import { CarouselWithControlsComponent } from "@Libraries/components/carousels/CarouselWithControls.component";
import {
  CarouselListModel,
  CarouselType,
} from "@Libraries/components/carousels/models/carousel-list.model";
import { TestimoniCarouselModel } from "@Libraries/components/carousels/models/carousel.model";
import { FC, useState } from "react";

export type TestimoniModel = {
  imageUrl: string;
  name: string;
  title: string;
  text: string;
};

export type MidasTestimoniProps = {
  carouselId: string;
  data: TestimoniModel[];
};

export const MidasTestimoniComponent: FC<MidasTestimoniProps> = ({
  carouselId,
  data,
}) => {
  const onNext = () => {
    let newCarouselState = { ...carouselState };
    newCarouselState.focusedIndex = ++carouselState.focusedIndex;

    if (newCarouselState.focusedIndex >= newCarouselState.carouselList.length) {
      newCarouselState.focusedIndex = 0;
      carouselState.focusedIndex = 0;
    }

    setCarouselState(newCarouselState);
  };

  const onPrev = () => {
    let newCarouselState = { ...carouselState };
    newCarouselState.focusedIndex = --carouselState.focusedIndex;

    if (newCarouselState.focusedIndex < 0) {
      newCarouselState.focusedIndex = newCarouselState.carouselList.length - 1;
      carouselState.focusedIndex = carouselState.carouselList.length - 1;
    }

    setCarouselState(newCarouselState);
  };

  const [carouselState, setCarouselState] = useState<CarouselListModel>({
    carouselId: carouselId,
    focusedIndex: 0,
    type: CarouselType.TESTIMONI,
    carouselList: data.map((item, index) => {
      return {
        key: `testimoni-${index}`,
        imageUrl: item.imageUrl,
        name: item.name,
        title: item.title,
        testimoni: item.text,
      } as TestimoniCarouselModel;
    }),
  });

  return (
    <div className="mt-16 md:mt-28 lg:mb-16">
      <div className="text-white font-bold responsive-header-title mx-7 md:mx-16 text-center">
        Sudah dipercaya oleh <span className="color-brand-yellow">+1400 Member Aktif</span>
      </div>
      <div className="mt-14">
        <CarouselWithControlsComponent
          {...carouselState}
          onNext={onNext}
          onPrev={onPrev}
        />
      </div>
    </div>
  );
};
