import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AffiliateProductModel } from "../data/affiliate-product.model";

export const GetAffiliateProductListUseCase: () => Promise<
  AffiliateProductModel[]
> = async () => {
  try {
    let response = await client.get(ApiConst.API_AFFILIATES + "/productList");
    let data = response.data.data;

    return data.map((item: any) => ({
      id: item.id,
      title: item.title,
    }));
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
