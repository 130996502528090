/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { ProfileUiModel } from "@Features/profile/domain/model/Profile.ui.model";
import { UpdateProfileUsecase } from "@Features/profile/domain/UpdateProfile.usecase";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { DividerComponent } from "@Libraries/components/divider/Divider.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { EMAIL_REGEX } from "@Libraries/utils/Regex";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";

type ProfileDetailFormInputs = {
  name: string;
  email: string;
  birthdate: string;
  phoneNumber: string;
  telegram: string;
};

export type ProfileSectionProps = ProfileUiModel & {
  onChangePasswordClicked: () => void;
  setNeedRefresh: (needRefresh: boolean) => void;
};

export const ProfileSectionComponent: FC<ProfileSectionProps> = ({
  email,
  fullname,
  id,
  birthdate,
  phone,
  pictureUrl,
  telegram,
  onChangePasswordClicked,
  setNeedRefresh,
}) => {
  const toasterContext = UseToasterContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileDetailFormInputs>();

  useEffect(() => {
    setValue("name", fullname);
    setValue("email", email);
    setValue("telegram", telegram ?? "");
    setValue("phoneNumber", phone ?? "");
    setValue("birthdate", birthdate ?? "");
  }, [fullname, email, telegram, phone, birthdate]);

  const onSubmit = (data: ProfileDetailFormInputs) => {
    let formData = new FormData();

    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phoneNumber);
    formData.append("birthdate", data.birthdate);
    formData.append("telegram", data.telegram);

    UpdateProfileUsecase(formData)
      .then((response) => {
        setNeedRefresh(true);
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Profile berhasil di perbarui!",
            "",
            ToasterType.SUCCESS
          ),
        ]);
      })
      .catch((error) =>
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Profile gagal di perbarui!",
            error.message,
            ToasterType.DANGER
          ),
        ])
      );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="text-xl font-bold">Data Diri</div>

      <div className="mt-8 flex flex-col w-full">
        <label className="font-normal" htmlFor="profile-name">
          Nama
        </label>
        <input
          type="text"
          className="input-form w-full"
          id="profile-name"
          placeholder="Masukkan nama"
          {...register("name", {
            required: "Name is required.",
          })}
        />
        {errors.name && (
          <p className="text-red-500 text-xs">{errors.name.message}</p>
        )}
      </div>

      <div className="grid grid-cols-1 gap-0 lg:gap-6 lg:grid-cols-2">
        <div className="mt-8 flex flex-col w-full">
          <label className="font-normal" htmlFor="profile-email">
            Email
          </label>
          <input
            type="email"
            className="input-form w-full"
            id="profile-email"
            placeholder="Masukkan email"
            {...register("email", {
              required: "Email is required.",
              pattern: {
                value: EMAIL_REGEX,
                message: "Invalid email address.",
              },
            })}
          />
          {errors.email && (
            <p className="text-red-500 text-xs">{errors.email.message}</p>
          )}
        </div>

        <div className="mt-8 flex flex-col w-full">
          <label className="font-normal" htmlFor="profile-telegram">
            Telegram
          </label>
          <div className="input-form w-full flex">
            <div className="font-black my-auto">@</div>
            <input
              type="text"
              className="bg-transparent outline-none ml-2"
              id="profile-telegram"
              placeholder="Masukkan username telegram"
              {...register("telegram", {
                required: "Telegram is required.",
              })}
            />
          </div>

          {errors.telegram && (
            <p className="text-red-500 text-xs">{errors.telegram.message}</p>
          )}
        </div>

        <div className="mt-8 flex flex-col w-full">
          <label className="font-normal" htmlFor="profile-phone">
            Nomor Telepon
          </label>
          <div className="input-form w-full flex">
            <div className="font-bold my-auto">+62</div>
            <input
              type="tel"
              className="bg-transparent outline-none ml-2"
              id="profile-phone"
              placeholder="cth: 81290003939"
              {...register("phoneNumber")}
            />
          </div>

          {errors.phoneNumber && (
            <p className="text-red-500 text-xs">{errors.phoneNumber.message}</p>
          )}
        </div>

        <div className="mt-8 flex flex-col w-full">
          <label className="font-normal" htmlFor="profile-birthdate">
            Tanggal Lahir
          </label>
          <input
            type="date"
            className="input-form w-full"
            id="profile-birthdate"
            placeholder="Masukkan tanggal lahir"
            {...register("birthdate")}
          />
          {errors.birthdate && (
            <p className="text-red-500 text-xs">{errors.birthdate.message}</p>
          )}
        </div>
      </div>

      <DividerComponent />

      <div className="flex flex-col lg:flex-row justify-between mt-8">
        <div className="my-auto mx-auto lg:mx-0">
          <span className="text-base font-semibold">Ubah password?</span>
          <button
            type="button"
            className="text-base font-bold color-brand-yellow ml-1 underline"
            onClick={onChangePasswordClicked}
          >
            Klik disini
          </button>
        </div>
        <div className="my-auto mx-auto lg:mx-0 mt-4 lg:mt-0">
          <MidasRoundedYellowButton
            label="Simpan perubahan"
            onClick={() => {}}
            isExternalLink={false}
          />
        </div>
      </div>

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </form>
  );
};
