import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const ValidateAffiliateNameUseCase: (
  name: string
) => Promise<boolean> = async (name: string) => {
  try {
    let response = await client.get(
      ApiConst.API_AFFILIATES + "/checkUniqueName/" + name
    );
    return response.data.data.isAvailable === true;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
