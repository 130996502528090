import { ApiConst, ApiFomo } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { UserSession } from "@Libraries/users/UserSession";

export const CheckoutUsecase: (
  cartId: string,
  email: string,
  password: string,
  name: string,
  phone: string,
  telegram: string,
  additionalInfo: any,
  cartData:any,
  affiliateName?: string,
) => Promise<string> = async (
  cartId,
  email,
  password,
  name,
  phone,
  telegram,
  additionalInfo,
  cartData,
  affiliateName
) => {
    let formData = new FormData();
    let formDataFomo = new FormData();

    let product = cartData.title.toLowerCase()

    switch(true){
      case (product.includes("membership") && product.includes("portofolio")):
        product = "Midas Full Benefit"
        break; 

      case (!product.includes("membership") && product.includes("portofolio")):
        product = "Midas Portofolio"
        break;   

      case (product.includes("Watchlist")):
        product = "Midas Watchlist"
        break;   
      
      default:
        product = "Midas Stocks Membership"
        break;  
    }

    if (!UserSession.checkIsLoggedIn()) {
      formData.append("email", email);
      formData.append("password", password);
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("telegram", telegram);
      formData.append("additional_info", JSON.stringify(additionalInfo));
    }
    
    formDataFomo.append("first_name", name == "" ? UserSession.getName().split(" ")[0] : name);
    formDataFomo.append("product_name",product);

    if (affiliateName) {
      formData.append("affiliate_name", affiliateName);
    }

    await client.post( ApiFomo.API_FOMO, formDataFomo );

    let response = await client.post(
      ApiConst.API_CHECKOUT + "/" + cartId,
      formData
    );

    if (response.data.data.token) {
      UserSession.loginWithToken(response.data.data.token);
    }

    return response.data.data.checkout_url;
  };
