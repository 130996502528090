import {
  DividerProps,
  MidasDivider,
} from "../components/widgets/Divider.component";

export class DividerModel {
  componentKey: string;
  thickness: number;
  indent: number;
  endIndent: number;
  colorHex: string;
  topSpace: number;
  bottomSpace: number;
  type: string;

  constructor(
    componentKey: string,
    thickness = 1,
    indent = 0,
    endIndent = 0,
    colorHex = "#9E9E9E",
    topSpace = 0,
    bottomSpace = 0,
    type = "solid"
  ) {
    this.componentKey = componentKey;
    this.thickness = thickness;
    this.indent = indent;
    this.endIndent = endIndent;
    this.colorHex = colorHex;
    this.topSpace = topSpace;
    this.bottomSpace = bottomSpace;
    this.type = type;
  }

  render() {
    return (
      <MidasDivider
        componentKey={this.componentKey}
        options={{
          thickness: this.thickness,
          indent: this.indent,
          endIndent: this.endIndent,
          colorHex: this.colorHex,
          topSpace: this.topSpace,
          bottomSpace: this.bottomSpace,
          type: this.type,
        }}
      />
    );
  }

  getJsonString() {
    return {
      type: "divider",
      options: {
        thickness: this.thickness,
        indent: this.indent,
        endIndent: this.endIndent,
        colorHex: this.colorHex,
        topSpace: this.topSpace,
        bottomSpace: this.bottomSpace,
        type: this.type,
      },
    };
  }

  jsonToModel(data: DividerProps) {
    this.thickness = data.options.thickness;
    this.indent = data.options.indent;
    this.endIndent = data.options.endIndent;
    this.colorHex = data.options.colorHex;
    this.topSpace = data.options.topSpace;
    this.bottomSpace = data.options.bottomSpace;
    this.type = data.options.type;
  }
}
