/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { CourseModel } from "@Features/dashboard/data/course.model";
import { GetCourseListUsecase } from "@Features/dashboard/domain/GetCourseList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export const CourseItemComponent: FC<CourseModel> = ({
  title,
  slug,
  cover_image_url,
  level,
  duration,
  part,
  videoCount,
}) => {
  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>Courses - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Courses" />
      </Helmet>
      <Link to={`/course/${slug}`}>
        <div className="rounded-3xl bg-midas-neutral-900 bg-opacity-40 text-white h-full pb-4">
          {cover_image_url !== "" ? (
            <img
              src={cover_image_url}
              alt={title}
              className="w-full h-1/2 object-cover rounded-t-3xl"
            />
          ) : (
            <div className="h-1/2 rounded-t-3xl color-brand-yellow"></div>
          )}
          <div className="p-5">
            <div className="flex">
              <div className="bg-gray-600 text-white text-xs rounded shadow w-fit py-1 px-2">
                {duration}
              </div>
              <div className="color-yellow ml-4 text-black font-bold text-xs rounded-xl shadow w-fit py-1 px-2">
                {level}
              </div>
            </div>
            <div className="mt-3 text-xl font-bold">{title}</div>
            <div className="mt-3 text-sm">
              {part} Part(s) | {videoCount} Video(s)
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export const CoursesSection = () => {
  const [courseList, setCourseList] = useState<CourseModel[]>([]);

  const toasterContext = UseToasterContext();

  useEffect(() => {
    GetCourseListUsecase()
      .then((response) => {
        setCourseList(response);
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal memuat course!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  }, []);

  return courseList.length === 0 ? (
    <MidasShimmering />
  ) : (
    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {courseList.map((item, index) => {
        return (
          <CourseItemComponent
            key={`course-item-${item.slug}-${index}`}
            {...item}
          />
        );
      })}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
