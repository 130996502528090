import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export type PageSettingListModel = {
  [key: string]: string;
};

export const LoadPageSettingUsecase: (
  optionKeys: string
) => Promise<PageSettingListModel> = async (optionKeys) => {
  try {
    let response = await client.get(
      ApiConst.API_LOAD_SETTINGS + "/" + optionKeys
    );
    let data = response.data.data;

    let result: PageSettingListModel = {};

    data.forEach((item: any) => {
      result[item.key] = item.value;
    });

    return result;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
