/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { FooterComponent } from "@Libraries/components/footers/Footer.component";
import { HeaderLogoOnly } from "@Libraries/components/headers/HeaderLogoOnly.component";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import { ToasterPosition } from "@Libraries/components/toast/Toast.model";
import { getSiteMenus } from "@Libraries/utils/Const";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PageModel } from "../data/Page.model";
import { LoadPageUsecase } from "../domain/LoadPage.usecase";
import { BaseComponentModel } from "./models/Base.model";
import { DividerModel } from "./models/divider.model";
import { IFrameModel } from "./models/iframe.model";
import { ImageModel } from "./models/image.model";
import { SpacerModel } from "./models/spacer.model";
import { TextModel } from "./models/text.model";
import { VideoModel } from "./models/video.model";
import { HeaderModel } from "./models/header.model";
import { PostDetailModel } from "./models/post-detail.model";
import { BulletModel } from "./models/bullet.model";
import { DisclaimerModel } from "./models/disclaimer.model";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export const Page = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const toasterContext = UseToasterContext();

  const [detail, setDetail] = useState<PageModel>();
  const [typeDetail, setTypeDetail] = useState<string>();

  useEffect(() => {
    LoadPageUsecase(params.slug ?? "")
      .then((response) => {
        setDetail(response);
        setTypeDetail(response.type);
      })
      .catch((errors) => {
        if (errors.message === "404") {
          navigate("/404");
        }
      });
  }, [params.slug]);

  const mapApiComponentToModel: (
    model: any,
    index: number
  ) => BaseComponentModel | null = (model, index) => {
    let resultModel = null;
    switch (model.type) {
      case "header":
        resultModel = new HeaderModel("header-" + index);
        break;
      case "text":
        resultModel = new TextModel("text-" + index);
        break;
      case "post-detail":
        resultModel = new PostDetailModel("post-detail-" + index);
        break;
      case "bullet":
        resultModel = new BulletModel("bullet-" + index);
        break;
      case "disclaimer":
        resultModel = new DisclaimerModel("disclaimer-" + index);
        break;
      case "divider":
        resultModel = new DividerModel("divider-" + index);
        break;
      case "spacer":
        resultModel = new SpacerModel("spacer-" + index);
        break;
      case "image":
        resultModel = new ImageModel("image-" + index);
        break;
      case "video":
        resultModel = new VideoModel("video-" + index);
        break;
      case "iframe":
        resultModel = new IFrameModel("iframe-" + index, "", "", detail?.title);
        break;
    }
    resultModel?.jsonToModel(model);
    return resultModel;
  };

  const isShowHeaderFromParams =
    (searchParams.get("show_header") ?? "1") === "1";
  const canShowHeader =
    detail !== null &&
    detail?.is_show_header === true &&
    isShowHeaderFromParams === true;

  const isShowFooterFromParams =
    (searchParams.get("show_footer") ?? "1") === "1";

  return (
    <div>
      {!detail && <MidasShimmering />}
      {detail && (
        <>
          <div>
            <Helmet encodeSpecialCharacters={true}>
              <title>{detail.title} - MIDAS Cuan</title>
              <meta
                name="description"
                content={`MIDAS Cuan - ${detail.title}`}
              />
            </Helmet>

            {detail !== null &&
              canShowHeader &&
              detail.header_type === "logo_only" && <HeaderLogoOnly />}

            {detail !== null &&
              canShowHeader &&
              detail.header_type === "with_menu" && (
                <HeaderWithMenu
                  shouldShowLoginButton={true}
                  menuItems={getSiteMenus()}
                />
              )}

            {JSON.parse(detail.content_json).map(
              (model: any, index: number) => {
                return mapApiComponentToModel(model, index)?.render();
              }
            )}

            {detail !== null &&
              isShowFooterFromParams &&
              detail?.is_show_footer === true && <FooterComponent />}

            {/* render Toaster if any */}
            {toasterContext.toastList.length > 0 && (
              <Toaster
                toastItems={toasterContext.toastList}
                isAutoDelete={true}
                position={ToasterPosition.BOTTOM_RIGHT}
                autoDeleteTimeInMilis={2000}
              />
            )}
          </div>
        </>
      )}
      <WaButtonFloating
        type={typeDetail}
      />
    </div>
  );
};
