import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const RegisterAffiliateUsecase: (
  affiliateName: string,
  bankHolderName: string,
  bankHolderNumber: string,
  bankName: string
) => Promise<string> = async (
  affiliateName,
  bankHolderName,
  bankHolderNumber,
  bankName
) => {
  try {
    let formData = new FormData();

    formData.append("affiliate_name", affiliateName);
    formData.append("status", "active");
    formData.append("bank_holder_name", bankHolderName);
    formData.append("bank_account_number", bankHolderNumber);
    formData.append("bank_name", bankName);

    let response = await client.post(ApiConst.API_AFFILIATES, formData);
    if (response.status === 200) {
      return response.data.message;
    }
    return "";
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
