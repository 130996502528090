import { FC } from "react";
import { CarouselItemModel } from "../models/carousel-list.model";
import { LinearTextCarouselModel } from "../models/carousel.model";

export type LinearTextCarouselProps = {
  carouselList: LinearTextCarouselModel[];
  focusedIndex: number;
  onClick?: (model: CarouselItemModel, index: number) => void;
  getTranslatePosition: (index: number) => string;
  getScaleValue: (index: number) => string;
  height: number;
};

export const LinearTextCarouselComponent: FC<LinearTextCarouselProps> = ({
  carouselList,
  focusedIndex,
  getScaleValue,
  getTranslatePosition,
  onClick,
  height,
}) => {
  return (
    <>
      {carouselList.map((item, index) => (
        <div
          key={item.key}
          className={`w-full md:w-1/3 absolute transition-all duration-500`}
          style={{
            transform: `translateX(${getTranslatePosition(
              index
            )}) scale(${getScaleValue(index)})`,
            height: height,
          }}
          onClick={() => onClick && onClick(item, index)}
        >
          <div className="w-full h-full bg-white p-6 rounded-xl relative">
            <img
              src={item.imageUrl}
              className={`w-14 h-14 object-cover rounded-full absolute top-6 left-6`}
              alt={item.altText}
            />
            <div className="mt-20 text-lg font-semibold">{item.text}</div>
          </div>
        </div>
      ))}
    </>
  );
};
