import { MidasLogo } from "@Assets/midas-logo";
import { default as Playstore } from "@Assets/playstore.png";
import { default as AppStore } from "@Assets/appstore.png";
import { UserSession } from "@Libraries/users/UserSession";
import {
  FaEnvelope,
  FaInstagram,
  FaMapMarkerAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { FooterSectionComponent } from "./FooterSection.component";
import { useEffect, useState } from "react";
import { FetchEbookURLSettingsUsecase } from "@Features/homepage/domain/FetchEbookurlSettings.usecase";
import { EBOOK_URL } from "@Libraries/utils/Const";

export const FooterComponent = () => {
  const [ebookUrl, setEbookUrl] = useState("");

  const runAdditionalMenuSetup = async () => {
    try {
      let response = await FetchEbookURLSettingsUsecase();
      setEbookUrl(response[EBOOK_URL]);
    } catch (error) {
      setEbookUrl("");
    }
  }

  useEffect(() => {
    runAdditionalMenuSetup();
  }, []);

  return (
    <div className="bg-midas-neutral-900 w-full flex flex-col py-12 px-4 lg:px-14">
      <div className="flex flex-col md:flex-row justify-center md:justify-between">
        <div className="my-auto">
          <MidasLogo className="" />
        </div>
        {UserSession.checkIsLoggedIn() &&
        UserSession.getUserSession().activeRoles.length > 0 ? (
          <div></div>
        ) : (
          <div className="flex text-right">
            <div className="text-xl font-normal text-white my-auto">
              Ready to get started?
            </div>
            <Link to={"/membership"}>
              <button className="color-yellow rounded-lg ml-5 font-bold text-lg p-3 my-auto">
                Get started
              </button>
            </Link>
          </div>
        )}
      </div>

      <div className="border-t my-8 border-white h-px"></div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="order-3 md:order-1">
          <FooterSectionComponent
            title="Hubungi kami"
            items={[
              {
                icon: <FaEnvelope size={16} />,
                label: "hello@midascuan.id",
                url: "",
                target: "_self",
              },
              {
                icon: <FaInstagram size={16} />,
                label: "@midascuan.id",
                url: "https://www.instagram.com/midascuan.id/",
                target: "_blank",
              },
              {
                icon: <FaWhatsapp size={16} />,
                label: "(+62) 878 8479 2402",
                url: "https://api.whatsapp.com/send?phone=6287884792402&text=Hai%20Midascuan.id.",
                target: "_blank",
              },
              {
                icon: <FaMapMarkerAlt size={16} />,
                label: "Ruko Tabespot, BSD G1 No.26, Pagedangan, Tangerang Regency, Banten 15339",
                // MY REPUBLIC PLAZA, Wing A, Zona 6, Green Office Park, Jalan BSD Grand Boulevard, BSD City, Sampora, Cisauk, Kab. Tangerang, Banten. 15345",
                url: "https://www.google.com/maps/dir//Ruko+Tabespot,+BSD+G1+No.26,+Pagedangan,+Tangerang+Regency,+Banten+15339/@-6.292228,106.5409125,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69fba22c9c75b5:0xfff67d13d5f7acab!2m2!1d106.6233144!2d-6.2922345?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D",
                target: "_blank",
              },
            ]}
          />
        </div>

        <div className="order-1 md:order-2">
          <FooterSectionComponent
            title="MIDASCUAN.ID"
            items={[
              {
                label: "MIDAS Stocks Membership",
                url: `${window.location.origin}/membership`,
                target: "_self",
              },
              {
                label: "(Recording) Webinar",
                url: "https://lynk.id/midascuanid/70Q9XzL",
                target: "_blank",
              },
              {
                label: "Stocks Key Insight",
                url: `${window.location.origin}/stocks-key-insights`,
                target: "_self",
              },
              {
                label: "Free Ebook",
                url: `${window.location.origin}/${ebookUrl}`,
                target: "_self",
              },
            ]}
          />
        </div>

        <div className="order-2 md:order-3">
          <FooterSectionComponent
            title=""
            items={[
              {
                label: "Disclaimer",
                url: `${window.location.origin}/disclaimer`,
                target: "_self",
              },
              {
                label: "Kebijakan Privasi",
                url: `${window.location.origin}/privacy-policy`,
                target: "_self",
              },
              {
                label: "Syarat & Ketentuan",
                url: `${window.location.origin}/syarat-ketentuan`,
                target: "_self",
              },
            ]}
          />
          <div className="flex gap-4 my-8">
              <a 
                className="w-1/4"
                href="https://play.google.com/store/apps/details?id=id.midascuan.app"
                target="_blank"
              >
                <img src={Playstore} />
              </a>

              <a 
                className="w-1/4"
                href="https://apps.apple.com/id/app/midascuan/id6502044634"
                target="_blank"
              >
                <img src={AppStore} />
              </a>
          </div>
        </div>
      </div>
    </div>
  );
};
