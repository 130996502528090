/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";

import { ToasterModel, ToasterPosition } from "./Toast.model";

export type ToasterProps = {
  position: ToasterPosition;
  toastItems: ToasterModel[];
  isAutoDelete: boolean;
  autoDeleteTimeInMilis: number;
};

export const Toaster: FC<ToasterProps> = ({
  position,
  toastItems,
  isAutoDelete,
  autoDeleteTimeInMilis,
}) => {
  const [items, setItems] = useState(toastItems);

  useEffect(() => {
    setItems([...toastItems]);
  }, [toastItems]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAutoDelete && items.length > 0 && toastItems.length > 0) {
        deleteToast(0);
      }
    }, autoDeleteTimeInMilis);

    return () => {
      clearInterval(interval);
    };
  }, [toastItems, items, isAutoDelete, autoDeleteTimeInMilis]);

  const deleteToast: (index: number) => void = (index) => {
    items.splice(index, 1);
    toastItems.splice(index, 1);
    setItems([...items]);
  };

  return (
    <div className={`notification-container ${position}`}>
      {toastItems.map((item, index) => {
        return (
          <div key={index} className={`notification toast ${item.type}`}>
            <button onClick={() => deleteToast(index)}>X</button>
            <div className="notification-image">{item.getIcon()}</div>
            <div>
              <p className="notification-title">{item.title}</p>
              <p className="notification-message">{item.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Toaster.defaultProps = {
  position: ToasterPosition.BOTTOM_RIGHT,
  autoDeleteTimeInMilis: 2000,
};
