/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { OfferModel } from "@Features/dashboard/data/offer.model";
import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { UserSession } from "@Libraries/users/UserSession";
import {
  MEMBER_UPGRADE_MEMBER,
  MEMBER_UPGRADE_PORTOFOLIO,
  USER_UPGRADE_SERVICES
} from "@Libraries/utils/Const";
import { Toaster } from "@Libraries/components/toast/Toast.component";

export const UpgradeSection = () => {
  const [offerList, setOfferList] = useState<OfferModel[]>([]);
  const [noUpgradeAvailable, setNoUpgradeAvailable] = useState(false);

  const toasterContext = UseToasterContext();

  useEffect(() => {
    async function fetchData() {
      try {
        let key = "";

        if (
          UserSession.isUserCanAccessMemberArea("member-area::portfolio") &&
          UserSession.isUserCanAccessMemberArea("member-area::courses")
        ) {
          setNoUpgradeAvailable(true);
          return;
        }

        if (UserSession.isUserCanAccessMemberArea("member-area::portfolio")) {
          key = MEMBER_UPGRADE_PORTOFOLIO;
        } else if (
          UserSession.isUserCanAccessMemberArea("member-area::courses")
        ) {
          key = MEMBER_UPGRADE_MEMBER;
        }else{
          key = USER_UPGRADE_SERVICES
        }

        if (key === "") return;

        let response = await LoadPageSettingUsecase(key);
        let dataString = response[key];

        setOfferList(JSON.parse(dataString));
      } catch (e) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Gagal mengambil data Offer",
            e.message,
            ToasterType.DANGER
          ),
        ]);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Upgrade - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Upgrade" />
      </Helmet>

      {offerList.length === 0 && noUpgradeAvailable === false && (
        <MidasShimmering />
      )}

      {offerList.length === 0 && noUpgradeAvailable && (
        <div className="w-full text-center text-white mt-4 bg-gray-500 p-4 rounded">
          Tidak ada Upgrade tersedia, status membership anda saat ini adalah{" "}
          <span className="font-bold">
            {UserSession.getUserSession()
              .activeRoles.map((item) => item.role.title)
              .join(" , ")}
          </span>
          .
        </div>
      )}

      {offerList.length > 0 && (
        <div className={`mt-4 grid grid-cols-1 gap-4`}>
          {offerList.map((item, index) => {
            return (
              <div
                className="w-full flex justify-between gap-4 rounded-3xl bg-midas-neutral-900 text-white p-4"
                key={`offer-item-${index}`}
              >
                <div className="flex gap-4">
                  <div className="text-xl font-bold">{item.productName}</div>
                  {item.showSale && (
                    <div className="my-auto text-lg text-red-500">SALE </div>
                  )}
                </div>

                <Link
                  className="button-yellow"
                  to={`/add-to-cart?product=${item.productId}`}
                  target="_blank"
                >
                  Upgrade Sekarang
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
