import { FC } from "react";
import { OnProgressProps } from "react-player/base";
import ReactPlayer from "react-player/lazy";

export type MidasVideoPlayerOptionsProps = {
  widthPercentage: number;
  showVideoControls: boolean;
  isAutoPlay: boolean;
  canDownload: boolean;
  videoSource: string;
  height?: number;
  isCenter?: boolean;
};

export type MidasVideoPlayerProps = {
  componentKey: string;
  videoUrl: string;
  options: MidasVideoPlayerOptionsProps;
  onProgress?: (state: OnProgressProps) => void
};

export const MidasVideoPlayer: FC<MidasVideoPlayerProps> = ({
  componentKey,
  videoUrl,
  options,
  onProgress
}) => {
  let controlList = "";

  if (options.canDownload === false) {
    controlList = "nodownload";
  }

  let height = "auto";
  if (options.videoSource === "youtube") {
    height = `${(9 / 16) * options.widthPercentage}%`;
  }

  let center = true;
  if (options.isCenter !== undefined && options.isCenter !== null) {
    center = options.isCenter;
  }

  if (options.height) {
    height = options.height + "px";
  }

  if (options.videoSource === "url" && videoUrl.split("/")[2] === "youtu.be") {
    height = "100%";
  }

  return (
    <ReactPlayer
      key={componentKey}
      className={`block ${center ? "mx-auto" : ""} ${
        options.videoSource === "youtube" && "aspect-video"
      }`}
      url={videoUrl}
      controls={options.showVideoControls}
      light={false}
      width={`${options.widthPercentage}%`}
      height={height}
      config={{
        file: {
          attributes: {
            autoPlay: options.isAutoPlay,
            controlsList: controlList,
          },
        },
      }}
      onProgress={onProgress}
    />
  );
};
