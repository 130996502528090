import { UserSession } from "@Libraries/users/UserSession";
import axios, { InternalAxiosRequestConfig } from "axios";
import { ApiConst } from "./ApiConst";

export const client = axios.create({
  baseURL: ApiConst.API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  let headers = { ...config.headers };

  if (UserSession.checkIsLoggedIn()) {
    headers["Authorization"] = "Bearer " + UserSession.getToken();
    headers["X-User-Id"] = UserSession.getId();
  }

  return {
    ...config,
    headers: headers,
  } as InternalAxiosRequestConfig<any>;;
});
