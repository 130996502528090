import { Helmet } from "react-helmet";

export const InvestmentPhilosophySection = () => {
  return (
    <div className="mt-4">
      <Helmet encodeSpecialCharacters={true}>
        <title>Investment Philosophy - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Investment Philosophy" />
      </Helmet>
      <div className="mt-4 rounded-lg bg-midas-neutral-500 p-4">
        <img
          decoding="async"
          className="border rounded-xl mx-auto"
          src="https://api.midascuan.id/storage/resources/Investment_Philosophy.webp"
          alt="dollar_naik"
          width="550"
          height="202"
        />
        <p className="mt-4 text-left">
          Kita percaya, dibalik setiap lembar saham ada perusahaan nyata yang
          bekerja. Untuk itu, kita berfokus untuk mencari perusahaan -
          perusahaan yang mampu:
        </p>
        <ul className="mx-8">
          <li style={{ listStyle: "lower-latin" }}>
            Memberikan high ROE kepada pemegang saham
          </li>
          <li style={{ listStyle: "lower-latin" }}>
            Laba tersebut nyata dalam bentuk cash flow
          </li>
          <li style={{ listStyle: "lower-latin" }}>
            Didukung oleh tailwind dan runway yang panjang (prospek masa depan
            yang cerah)
          </li>
          <li style={{ listStyle: "lower-latin" }}>
            Perusahaan tersebut bisa menginvestasikan kembali labanya untuk laba
            lebih besar di masa depan (reinvestment)
          </li>
          <li style={{ listStyle: "lower-latin" }}>
            Bisa dibeli diharga yang wajar atau lebih murah.
          </li>
        </ul>
        <p className="mt-4 text-left">
          Kita tidak hanya fokus "yang penting cuan",{" "}
          <b>tapi kita juga fokus pada CARA untuk mendapatkan cuan itu.</b>
        </p>
        <p className="mt-4 text-left">
          Jika untuk mendapatkan cuan itu memerlukan mengambil risiko yang amat
          besar, kita lebih baik skip dan mencari peluang baru yang lebih masuk
          akal.
        </p>
        <p className="text-lg font-bold mt-6">
          Apa yang kita maksud dengan risiko?
        </p>
        <div className="border-t border-brand-yellow w-full"></div>
        <p className="mt-4 text-left">
          Risiko menurut kami adalah kemungkinan buruk yang mungkin terjadi pada
          bisnis di perusahaan yang kita beli sahamnya. Kita menganalisa apakah
          pesaingnya bisa merebut market sharenya, apakah bisnis model masih
          relevan/sudah ditinggalkan?{" "}
          <b>Semua ini tidak ada hubungannya dengan VOLATILITAS HARGA.</b>
        </p>
        <p className="mt-4 text-left">
          Saham perusahaan yang bagus mungkin saja harganya naik dan turun dalam
          jangka pendek, tapi jika fokus kita adalah jangka panjang, volatilitas
          harga jangka pendek sudah menjadi tidak relevan lagi.
        </p>
        <p className="mt-4 text-left">
          Seperti yang Benjamin Graham katakan,
          <i>
            "dalam jangka pendek, pasar saham adalah mesin voting. Tapi dalam
            jangka panjang, pasar saham adalah mesin timbangan.
          </i>
        </p>

        <p className="mt-4 text-left">
          Dengan menerapkan metode yang kita jabarkan ini, seperti yang sudah
          diterapkan oleh banyak investor besar lainnya, kita berharap bisa
          menghasilkan "compounding machine" yang mampu menumbuhkan portfolio
          investasi kita dalam jangka panjang.
        </p>
        <p className="mt-4 font-bold">
          Inilah saham harta karun yang kita maksud.
        </p>

        <p className="mt-10 text-left">Have a great investment journey,</p>
        <p className="font-bold">Midas Team.</p>
      </div>
    </div>
  );
};
