import { PageModel } from "@Features/dynamic_page/data/Page.model";
import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const GetPortfolioListUsecase: () => Promise<PageModel[]> = async () => {
  try {
    let response = await client.get(ApiConst.API_PORTFOLIO);
    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
