import { MemberRole } from "@Features/profile/data/Profile.model";
import { GetProfileUsecase } from "@Features/profile/domain/GetProfile.usecase";
import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import secureLocalStorage from "react-secure-storage";

export const UserSession = (function () {
  const STORAGE_KEY = "MIDAS_USER_SESSION";

  let userId: number = 0;
  let name: string = "";
  let email: string = "";
  let isLoggedIn: boolean = false;
  let token: string = "";
  let role: string = "";
  let pictureUrl: string = "";
  let password: string = "";
  let activeRoles: MemberRole[] = [];
  let additionalInfo: { [key: string]: any } = {};

  const loginWithToken = (mToken: string) => {
    token = mToken;
    isLoggedIn = true;

    setLocalStorageData();
  };

  const _tryLogin: (
    email: string,
    mPassword: string,
    rememberMe: boolean
  ) => void = async (email, mPassword, rememberMe) => {
    activeRoles = [];
    additionalInfo = {};

    try {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("password", mPassword);

      let response = await client.post(ApiConst.API_LOGIN, formData);
      let data = response.data.data;

      userId = data.user.id;
      name = data.user.fullname;
      email = data.user.email;
      isLoggedIn = true;
      token = data.token;
      role = data.user.role;
      pictureUrl = data.user.picture_url;
      activeRoles = [
        ...new Set([
          ...activeRoles,
          ...data.user.member_active_roles.map((itemRole: MemberRole) => {
            return {
              expired_date: itemRole.expired_date,
              role: {
                title: itemRole.role.title,
                role: itemRole.role.role,
                access: itemRole.role.access,
                accessArray: itemRole.role.access.split(";"),
              },
            };
          }),
        ]),
      ];
      if (
        data.user.additional_info !== null ||
        data.user.additional_info !== undefined
      ) {
        additionalInfo = {
          ...additionalInfo,
          ...JSON.parse(data.user.additional_info),
        };
      }

      if (rememberMe) password = mPassword;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };

  const login: (
    mEmail: string,
    password: string,
    rememberMe: boolean
  ) => Promise<string> = async (mEmail, password, rememberMe) => {
    try {
      await _tryLogin(mEmail, password, rememberMe);
      setLocalStorageData();
      return "Login success";
    } catch (error) {
      throw error;
    }
  };

  const getUserSession = () => {
    return {
      userId: userId,
      name: name,
      email: email,
      isLoggedIn: isLoggedIn,
      token: token,
      role: role,
      pictureUrl: pictureUrl,
      additionalInfo: additionalInfo,
      activeRoles: activeRoles,
    };
  };

  const setUserSession = (
    mUserId: number,
    mName: string,
    mEmail: string,
    mToken: string,
    mRole: string,
    mActiveRoles: MemberRole[],
    mPictureUrl?: string | null,
    mAdditionalInfo?: string
  ) => {
    userId = mUserId;
    name = mName;
    email = mEmail;
    isLoggedIn = true;
    token = mToken;
    role = mRole;
    pictureUrl = mPictureUrl ?? "";
    activeRoles = mActiveRoles;
    additionalInfo = JSON.parse(mAdditionalInfo ?? "{}");

    setLocalStorageData();
  };

  const logout = async () => {
    try {
      await client.post(ApiConst.API_LOGOUT);
      emptySession();
      secureLocalStorage.clear();
    } catch (error) {}
  };

  const emptySession = () => {
    userId = 0;
    name = "";
    email = "";
    isLoggedIn = false;
    token = "";
    role = "";
    pictureUrl = "";
    additionalInfo = {};

    setLocalStorageData();
  };

  const setLocalStorageData = () => {
    if (typeof Storage !== "undefined") {
      secureLocalStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          userId: userId,
          name: name,
          email: email,
          isLoggedIn: isLoggedIn,
          token: token,
          role: role,
          pictureUrl: pictureUrl,
          password: password,
          activeRoles: activeRoles,
          additionalInfo: additionalInfo,
        })
      );
    }
  };

  const getSessionFromLocalStorage = () => {
    if (typeof Storage !== "undefined") {
      if (secureLocalStorage.getItem(STORAGE_KEY) !== null) {
        let sessionString = secureLocalStorage.getItem(STORAGE_KEY)?.toString();
        let session = JSON.parse(sessionString ?? "");

        userId = session.userId;
        name = session.name;
        email = session.email;
        isLoggedIn = session.isLoggedIn;
        token = session.token;
        role = session.role;
        pictureUrl = session.pictureUrl;
        password = session.password;
        activeRoles = session.activeRoles;
        additionalInfo = session.additionalInfo;
      }
    }
  };

  const checkIsLoggedIn = () => isLoggedIn;

  const getToken = () => token;

  const getName = () => name;

  const getId = () => userId;

  const getEmail = () => email;

  const getPicture = () => pictureUrl;

  const getAdditionalInfo = () => additionalInfo;

  const refreshUserData = () => {
    GetProfileUsecase.execute()
      .then((response) => {
        UserSession.setUserSession(
          response.id,
          response.fullname,
          response.email,
          UserSession.getToken(),
          response.role,
          response.memberActiveRoles,
          response.pictureUrl,
          response.additionalInfo ?? "{}"
        );
      })
      .catch((error) => {
        if (error.message === "Not Authorized") {
          let baseUrl = window.location.origin;
          UserSession.emptySession();
          window.location.href = `${baseUrl}/login?continue=${window.location.href}`;
        }
      });
  };

  const isUserCanAccessMemberArea = (access?: string) => {
    if (role === "superadmin") return true;

    let canAccessMemberArea = false;
    let roleAccess = "frontend::member-area";
    if (access) roleAccess = `frontend::${access}`;

    for (let i = 0; i < activeRoles.length; i++) {
      canAccessMemberArea = activeRoles[i].role.accessArray.some((item) =>
        item.includes(roleAccess)
      );

      if (canAccessMemberArea) {
        break;
      }
    }

    return canAccessMemberArea;
  };

  getSessionFromLocalStorage();

  return {
    login: login,
    logout: logout,
    setUserSession: setUserSession,
    getUserSession: getUserSession,
    checkIsLoggedIn: checkIsLoggedIn,
    getToken: getToken,
    getId: getId,
    getName: getName,
    getEmail: getEmail,
    getPicture: getPicture,
    getAdditionalInfo: getAdditionalInfo,
    refreshUserData: refreshUserData,
    loginWithToken: loginWithToken,
    emptySession: emptySession,
    isUserCanAccessMemberArea: isUserCanAccessMemberArea,
  };
})();
