import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export type DashboardSidebarItemProps = {
  icon: ReactNode;
  label: string;
  isActive: boolean;
  destination: string;
  isSpecialMenu: boolean;
};

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = ({
  icon,
  label,
  isActive,
  destination,
  isSpecialMenu,
}) => {
  return (
    <Link
      to={destination}
      className={`flex w-full rounded-r-full px-7 py-4 ${
        isActive && !isSpecialMenu ? "bg-2e2e2e" : ""
      } ${isSpecialMenu ? "text-black color-yellow" : "text-white"}`}
    >
      <div
        className={`my-auto ${
          isActive && !isSpecialMenu ? "color-brand-yellow" : "text-white"
        } ${isSpecialMenu ? "text-white" : ""}`}
      >
        {icon}
      </div>
      <div className="my-auto ml-3 text-sm overflow-hidden whitespace-nowrap">
        {label}
      </div>
    </Link>
  );
};
