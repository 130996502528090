import {
    MidasTextView,
    TextAlignType,
    TextViewProps,
} from "../components/widgets/Text.component";

export class DisclaimerModel {
    componentKey: string;
    text: string;
    width: number;
    backgroundColorHex: string;
    textColorHex: string;
    textSize: number;
    textAlign: TextAlignType;

    constructor(
        componentKey: string,
        text = `<div class="flex justify-center">
    <div class="w-full md:w-1/2 mx-4">
        <i><b>Disclaimer: Artikel analisa ini bukanlah merupakan sebuah rekomendasi ataupun ajakan untuk jual atau beli saham tertentu. 
Artikel ini hanya untuk kepentingan edukasi dan informasi saja. 
Segala data yang disajikan bersumber dari publikasi yang disampaikan kepada publik melalui Annual Report, 
Public Expose ataupun Laporan Keuangan emiten.</i></b><br />
        <br/>
        <i><b>Disclaimer: Midas Portofolio TIDAK mengelola dana dan selalu menganjurkan para subscribersnya untuk selalu meneliti terlebih dahulu saham yang ingin dibeli. Penyebutan nama saham di post ini bukan merupakan ajakan beli atau jual. 
	</i></b><br />
    </div>
</div>`,
        width = 100,
        backgroundColorHex = "transparent",
        textColorHex = "#000000",
        textSize = 20,
        textAlign: TextAlignType = "left"
    ) {
        this.componentKey = componentKey;
        this.text = text;
        this.width = width;
        this.backgroundColorHex = backgroundColorHex;
        this.textColorHex = textColorHex;
        this.textSize = textSize;
        this.textAlign = textAlign;
    }

    render() {
        return (
            <MidasTextView
                componentKey={this.componentKey}
                text={this.text}
                options={{
                    width: this.width,
                    backgroundColorHex: this.backgroundColorHex,
                    textColorHex: this.textColorHex,
                    textSize: this.textSize,
                    textAlign: this.textAlign,
                }}
            />
        );
    }

    getJsonString() {
        return {
            type: "disclaimer",
            text: this.text,
            options: {
                width: this.width,
                backgroundColorHex: this.backgroundColorHex,
                textColorHex: this.textColorHex,
                textSize: this.textSize,
                textAlign: this.textAlign,
            },
        };
    }

    jsonToModel(data: TextViewProps) {
        this.text = data.text;
        this.width = data.options.width;
        this.backgroundColorHex = data.options.backgroundColorHex;
        this.textColorHex = data.options.textColorHex;
        this.textSize = data.options.textSize;
        this.textAlign = data.options.textAlign;
    }
}
