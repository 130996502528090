import { PaymentMethod } from "@Features/checkout/data/payment-method.model";
import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import { CHECKOUT_AVAILABLE_PAYMENTS } from "@Libraries/utils/Const";
import { useEffect } from "react";
import { useState } from "react";
import { FC } from "react";
import { CheckoutFormProps } from "../CheckoutForm.page";

export const CheckoutPaymentForm: FC<CheckoutFormProps> = ({
  register,
  watch,
}) => {
  const [availablePayments, setAvailablePayments] = useState<PaymentMethod[]>(
    []
  );

  useEffect(() => {
    LoadPageSettingUsecase(`${CHECKOUT_AVAILABLE_PAYMENTS};`)
      .then((response) =>
        setAvailablePayments(JSON.parse(response[CHECKOUT_AVAILABLE_PAYMENTS]))
      )
      .catch((error) => {});
  }, []);

  return (
    <div className="mt-6">
      <div className="text-form-title">Payment</div>

      <div className="text-lg color-333333 mt-6">Choose Payment Method</div>

      <div className="border rounded-lg shadow mt-4 grid grid-cols-1 md:grid-cols-2 md:divide-x">
        {availablePayments.map((item, index) => {
          return (
            <div key={`available-payment-choice-${index}`}>
              <div className="w-full font-bold text-sm p-4 bg-white flex gap-3">
                <input
                  type={"radio"}
                  value={item.key}
                  width={24}
                  height={24}
                  {...register("paymentMethod")}
                />
                <div className="my-auto">{item.title}</div>
                {item.image.map((url) => (
                  <img
                    key={`logo-${item.title}-${url}`}
                    src={url}
                    alt={item.title}
                    className="h-5 object-scale-down my-auto"
                  />
                ))}
              </div>
              <div className="h-px border-t border-midas-neutral-500"></div>
            </div>
          );
        })}
      </div>

      <p className="text-justify mt-4 font-normal text-sm">
        Dengan mendaftar & beli kamu setuju dengan{" "}
        <strong>syarat dan ketentuan</strong> dan{" "}
        <strong>kebijakan privasi</strong> midascuan.id
      </p>
    </div>
  );
};
