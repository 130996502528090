import { FC } from "react";

export type DividerOptions = {
  thickness: number;
  indent: number;
  endIndent: number;
  colorHex: string;
  topSpace: number;
  bottomSpace: number;
  type: string;
};

export type DividerProps = {
  componentKey: string;
  options: DividerOptions;
};

export const MidasDivider: FC<DividerProps> = ({ componentKey, options }) => {
  return (
    <div
      key={componentKey}
      style={{
        marginLeft: options.indent + "px",
        marginTop: options.topSpace + "px",
        marginRight: options.endIndent + "px",
        marginBottom: options.bottomSpace + "px",
        borderTop:
          options.thickness + "px " + options.type + " " + options.colorHex,
      }}
    />
  );
};
