import {
  FaAngleDoubleUp,
  FaBong,
  FaBookOpen,
  FaChalkboardTeacher,
  FaForward,
  FaLaptop,
  FaListAlt,
  FaNetworkWired,
  FaUsers,
  FaVideo,
  FaViruses,
  FaHome,
} from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";

export const MemberAreaMenu = [
  {
    label: "(Home) Starting Guide",
    icon: <FaHome size={28} />,
    path: "/member-area",
    lastPath: "member-area",
    access: "member-area",
    isSpecialMenu: false,
  },
  {
    label: "Investment Philosophy",
    icon: <FaBong size={28} />,
    path: "/member-area/philosophy",
    lastPath: "philosophy",
    access: "member-area::philosophy",
    isSpecialMenu: false,
  },
  {
    label: "Analisa Saham",
    icon: <FaBookOpen size={28} />,
    path: "/member-area/analysis",
    lastPath: "analysis",
    access: "member-area::analysis",
    isSpecialMenu: false,
  },
  {
    label: "Midas Watchlist (NEW)",
    icon: <FaListAlt size={28} />,
    path: "/member-area/watchlist",
    lastPath: "watchlist",
    access: "member-area::watchlist",
    isSpecialMenu: false,
  },
  {
    label: "Portfolio",
    icon: <TbReportAnalytics size={28} />,
    path: "/member-area/portfolio",
    lastPath: "portfolio",
    access: "member-area::portfolio",
    isSpecialMenu: false,
  },
  {
    label: "Courses",
    icon: <FaChalkboardTeacher size={28} />,
    path: "/member-area/courses",
    lastPath: "courses",
    access: "member-area::courses",
    isSpecialMenu: false,
  },
  {
    label: "MIDAS Jarvis",
    icon: <FaLaptop size={28} />,
    path: "/member-area/jarvis",
    lastPath: "jarvis",
    access: "member-area::jarvis",
    isSpecialMenu: false,
  },
  {
    label: "Tools",
    icon: <FaViruses size={28} />,
    path: "/member-area/tools",
    lastPath: "tools",
    access: "member-area::tools",
    isSpecialMenu: false,
  },
  {
    label: "Grup Diskusi",
    icon: <FaUsers size={28} />,
    path: "/member-area/groups",
    lastPath: "groups",
    access: "member-area::groups",
    isSpecialMenu: false,
  },
  {
    label: "Rekaman",
    icon: <FaVideo size={28} />,
    path: "/member-area/records",
    lastPath: "records",
    access: "member-area::records",
    isSpecialMenu: false,
  },
  {
    label: "Affiliate",
    icon: <FaNetworkWired size={28} />,
    path: "/affiliate",
    lastPath: "affiliate",
    access: "member-area",
    isSpecialMenu: false,
  },
  {
    label: "Perpanjang",
    icon: <FaForward size={28} />,
    path: "/member-area/perpanjang",
    lastPath: "perpanjang",
    access: "",
    isSpecialMenu: true,
  },
  {
    label: "Upgrade",
    icon: <FaAngleDoubleUp size={28} />,
    path: "/member-area/upgrade",
    lastPath: "upgrade",
    access: "",
    isSpecialMenu: true,
  },
];
