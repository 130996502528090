/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { useState } from "react";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { Helmet } from "react-helmet";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export type CuanCalculatorInput = {
  modalAwal: string;
  setoranTahunan: string;
  yearsToGrow: number;
  pertumbuhan: number;
  waktuSetoran: string;
};

export const CuanCalculatorPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CuanCalculatorInput>();
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [calculationResult, setCalculationResult] = useState<number>();

  const onSubmit = (data: CuanCalculatorInput) => {
    setIsShowLoading(true);

    let modalAwalNumber = convertToNumber(data.modalAwal);
    let setoranTahunanNumber = convertToNumber(data.setoranTahunan);

    let result = 0;

    if (data.waktuSetoran === "awal") {
      result =
        (modalAwalNumber + setoranTahunanNumber) *
        (1 + data.pertumbuhan / 100) ** data.yearsToGrow +
        setoranTahunanNumber *
        (((1 + data.pertumbuhan / 100) ** data.yearsToGrow - 1) /
          (data.pertumbuhan / 100)) -
        setoranTahunanNumber;
    } else {
      if (modalAwalNumber === 0) modalAwalNumber = 1;
      result =
        modalAwalNumber * (1 + data.pertumbuhan / 100) ** data.yearsToGrow +
        data.yearsToGrow *
        (((1 + data.pertumbuhan / 100) ** data.yearsToGrow - 1) /
          (data.pertumbuhan / 100));
    }

    setCalculationResult(result);
    setIsShowLoading(false);
  };

  const formatCurrencyInput = (e: any) => {
    let currency = e.target.value.replaceAll(".", "").replaceAll(",", "");
    e.target.value = formatToCurrency(currency).replaceAll("Rp", "");
  };

  const convertToNumber = (stringNumber: string) => {
    return parseInt(stringNumber.replaceAll(/[^0-9]/g, ""));
  };

  return (
    <div className="w-screen p-5 md:p-10">
      <Helmet encodeSpecialCharacters={true}>
        <title>Cuan Kalkulator - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Cuan Kalkulator" />
      </Helmet>
      <div className="bg-white rounded pb-5 mx-auto w-full md:w-1/2">
        <div className="text-center bg-sidebar color-brand-yellow text-2xl font-bold p-4">
          Cuan Kalkulator
        </div>
        <form
          className="pl-5 pr-5 pb-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="font-bold text-lg my-auto">Modal Awal</div>
          <div className="col-span-1 md:col-span-2 my-auto">
            <input
              type={"text"}
              className="form-input "
              placeholder="0"
              {...register("modalAwal", {
                required: "Modal awal harus diisi.",
                onChange: formatCurrencyInput,
                min: {
                  value: 0,
                  message: "Modal awal minimal adalah 0",
                },
              })}
            />
            {errors.modalAwal && (
              <p className="text-red-500 text-xs">{errors.modalAwal.message}</p>
            )}
          </div>

          <div className="font-bold text-lg my-auto">Setoran Tahunan</div>
          <div className="col-span-1 md:col-span-2 my-auto">
            <input
              type={"text"}
              className="form-input "
              placeholder="0"
              {...register("setoranTahunan", {
                required: "Setoran awal harus diisi.",
                onChange: formatCurrencyInput,
                min: {
                  value: 0,
                  message: "Setoran awal minimal adalah 0",
                },
              })}
            />
            {errors.setoranTahunan && (
              <p className="text-red-500 text-xs">
                {errors.setoranTahunan.message}
              </p>
            )}
          </div>

          <div className="font-bold text-lg my-auto">Years to grow</div>
          <div className="col-span-1 md:col-span-2 my-auto">
            <input
              type={"number"}
              className="form-input "
              placeholder="0"
              {...register("yearsToGrow", {
                required: "Years to grow harus diisi.",
                min: {
                  value: 1,
                  message: "Years to grow minimal adalah 1",
                },
              })}
            />
            {errors.yearsToGrow && (
              <p className="text-red-500 text-xs">
                {errors.yearsToGrow.message}
              </p>
            )}
          </div>

          <div className="font-bold text-lg my-auto">Pertumbahan (%)</div>
          <div className="col-span-1 md:col-span-2 my-auto">
            <input
              type={"number"}
              className="form-input"
              placeholder="0"
              {...register("pertumbuhan", {
                required: "Pertumbuhan harus diisi.",
                min: {
                  value: 1,
                  message: "Pertumbuhan harus lebih besar dari 0",
                },
              })}
            />
            {errors.pertumbuhan && (
              <p className="text-red-500 text-xs">
                {errors.pertumbuhan.message}
              </p>
            )}
          </div>

          <div className="font-bold my-auto">Melakukan setoran</div>
          <div className="col-span-1 md:col-span-2 my-auto">
            <select
              className="form-input"
              {...register("waktuSetoran", {
                required: "Waktu setoran harus diisi.",
              })}
            >
              <option value={"awal"}>Pada Awal Tahun</option>
              <option value={"akhir"}>Pada Akhir Tahun</option>
            </select>
            {errors.waktuSetoran && (
              <p className="text-red-500 text-xs">
                {errors.waktuSetoran.message}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="button-yellow mt-8 w-full col-span-full"
          >
            Hitung
          </button>
        </form>

        {(isShowLoading || calculationResult) && (
          <div className="border-b-2 border-black w-full"></div>
        )}
        {isShowLoading && (
          <div className="flex justify-center mt-4">
            <MidasShimmering />
          </div>
        )}
        {calculationResult && (
          <div className="text-center font-bold text-xl mt-4">
            {formatToCurrency(calculationResult)}
          </div>
        )}
      </div>
      <WaButtonFloating/>
    </div>
  );
};
