import { MemberRole } from "@Features/profile/data/Profile.model";
import { convertDateFromApiToView } from "@Libraries/utils/Formatter";
import { FC } from "react";

export type MembershipSectionProps = {
  memberRoles: MemberRole[];
};

export const MembershipSectionComponent: FC<MembershipSectionProps> = ({
  memberRoles,
}) => {
  return (
    <>
      <div className="text-xl font-bold">Data Membership</div>

      <div className="mt-8 w-full grid grid-cols-2 gap-4">
        <div className="font-bold text-center">Jenis Akun</div>
        <div className="font-bold text-center">Masa Aktif</div>

        {memberRoles.map((item, index) => {
          return (
            <>
              <div key={`role-title-${index}`} className="text-center">
                {item.role.title}
              </div>
              <div key={`role-expired-${index}`} className="text-center">
                {convertDateFromApiToView(item.expired_date)}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
