import { FC } from "react";
import { MidasRoundedYellowButton } from "../buttons/MidasRoundedYellowButton.component";
import { TextWithImageProps } from "./types/TextWithImage.type";

export const TextWithRightImageComponent: FC<TextWithImageProps> = ({
  image,
  textChildren,
  showButton,
  buttonLabel,
  onClick,
  destination,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-20 mt-7 mx-7 md:mx-28 md:mt-12 text-left">
      <div className="my-auto">
        {textChildren}
        {showButton === true && buttonLabel && onClick && (
          <MidasRoundedYellowButton
            additionalClass="mt-8 rounded-full text-2xl"
            label={buttonLabel}
            onClick={onClick}
            isExternalLink={false}
            urlDestination={destination}
          />
        )}
      </div>
      <div className="hidden md:block mx-auto my-auto">{image}</div>
    </div>
  );
};
