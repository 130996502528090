import secureLocalStorage from "react-secure-storage";
import { CART_KEY } from "./SaveCartId.usecase";

export const GetCartId: () => string = () => {
  if (typeof Storage !== "undefined") {
    if (secureLocalStorage.getItem(CART_KEY) !== null) {
      return secureLocalStorage.getItem(CART_KEY)?.toString() ?? "";
    }
  }

  return "";
};
