import {
    MidasTextView,
    TextAlignType,
    TextViewProps,
} from "../components/widgets/Text.component";

export class BulletModel {
    componentKey: string;
    text: string;
    width: number;
    backgroundColorHex: string;
    textColorHex: string;
    textSize: number;
    textAlign: TextAlignType;

    constructor(
        componentKey: string,
        text = `<div class="flex justify-center">
    <div class="w-full md:w-1/2 mx-4">
        <p> 
          <ol style="list-style-type: decimal;padding-left:50px">
            <li> 
          
            </li>
          </ol>
	  </p><br />
    </div>
</div>`,
        width = 100,
        backgroundColorHex = "transparent",
        textColorHex = "#000000",
        textSize = 20,
        textAlign: TextAlignType = "center"
    ) {
        this.componentKey = componentKey;
        this.text = text;
        this.width = width;
        this.backgroundColorHex = backgroundColorHex;
        this.textColorHex = textColorHex;
        this.textSize = textSize;
        this.textAlign = textAlign;
    }

    render() {
        return (
            <MidasTextView
                componentKey={this.componentKey}
                text={this.text}
                options={{
                    width: this.width,
                    backgroundColorHex: this.backgroundColorHex,
                    textColorHex: this.textColorHex,
                    textSize: this.textSize,
                    textAlign: this.textAlign,
                }}
            />
        );
    }

    getJsonString() {
        return {
            type: "bullet",
            text: this.text,
            options: {
                width: this.width,
                backgroundColorHex: this.backgroundColorHex,
                textColorHex: this.textColorHex,
                textSize: this.textSize,
                textAlign: this.textAlign,
            },
        };
    }

    jsonToModel(data: TextViewProps) {
        this.text = data.text;
        this.width = data.options.width;
        this.backgroundColorHex = data.options.backgroundColorHex;
        this.textColorHex = data.options.textColorHex;
        this.textSize = data.options.textSize;
        this.textAlign = data.options.textAlign;
    }
}
