import { FC } from "react";

export type TextAlignType =
  | "start"
  | "end"
  | "left"
  | "right"
  | "center"
  | "justify"
  | "match-parent";

export type TextViewOptions = {
  width: number;
  textColorHex: string;
  backgroundColorHex: string;
  textAlign: TextAlignType;
  textSize: number;
};

export type TextViewProps = {
  componentKey: string;
  text: string;
  options: TextViewOptions;
};

export const MidasTextView: FC<TextViewProps> = ({
  componentKey,
  text,
  options,
}) => {
  return (
    <div
      key={componentKey}
      style={{
        width: options.width + "%",
        color: options.textColorHex,
        backgroundColor: options.backgroundColorHex,
        textAlign: options.textAlign,
        fontSize: options.textSize,
        margin: "auto",
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
