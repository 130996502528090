/* eslint-disable react-hooks/exhaustive-deps */
import { EyeIcon, EyeSlashIcon } from "@Assets/common.assets";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { EMAIL_REGEX } from "@Libraries/utils/Regex";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

type LoginFormInputs = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export const LoginPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>();

  useEffect(() => {
    if (UserSession.checkIsLoggedIn()) {
      redirectUser();
    }
  }, []);

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      setSuccessMessage(
        await UserSession.login(data.email, data.password, data.rememberMe)
      );
      setErrorMessage("");
      redirectUser();
    } catch (error: any) {
      setErrorMessage(error.message);
      setSuccessMessage("");
    }
  };

  const redirectUser = () => {
    setTimeout(function () {
      let redirectLocation = searchParams.get("continue");
      if (redirectLocation === null) {
        redirectLocation = "/member-area";
        navigate(redirectLocation);
      } else {
        window.location.href = redirectLocation;
      }
    }, 500);
  };

  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll">
      <HeaderWithMenu
        shouldShowLoginButton={false}
        menuItems={getSiteMenus()}
      />
      <div className="h-full flex justify-evenly">
        <div className="w-full h-full hidden lg:block text-white"></div>
        <div className="w-full h-full text-white">
          <form
            className="m-6 w-fit mx-auto lg:my-auto lg:mt-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="text-2xl font-medium">Welcome back!</div>
            <div className="mt-8 font-bold text-3xl">Sign in</div>

            {/* error message */}
            {successMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-green-500 text-justify">
                {successMessage}
              </div>
            )}

            {/* error message */}
            {errorMessage !== "" && (
              <div className="w-full mt-6 rounded p-2 text-white text-xs bg-red-500 text-justify">
                {errorMessage}
              </div>
            )}

            <div className="mt-8 flex flex-col">
              <label className="font-normal" htmlFor="login-email">
                Email
              </label>
              <input
                type="text"
                className="input-form"
                id="login-email"
                placeholder="Enter your email"
                {...register("email", {
                  required: "Email is required.",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Invalid email address.",
                  },
                  onChange: (e) => (e.target.value = e.target.value.trim()),
                })}
              />
              {errors.email && (
                <p className="text-red-500 text-xs">{errors.email.message}</p>
              )}
            </div>

            <div className="mt-8 flex flex-col">
              <label className="font-normal" htmlFor="login-password">
                Password
              </label>
              <div className="flex w-96 border border-282828 rounded-md px-6 py-4 bg-282828 mt-3 text-sm">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full bg-282828 outline-none"
                  id="login-password"
                  placeholder="Enter your password"
                  {...register("password", {
                    required: "Password is required.",
                  })}
                />
                <div
                  className="w-6 h-6 color-brand-yellow"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                </div>
              </div>
              {errors.password && (
                <p className="text-red-500 text-xs">
                  {errors.password.message}
                </p>
              )}
            </div>

            <div className="mt-8 flex justify-between text-xs font-light text-white">
              <div className="flex">
                <input type="checkbox" {...register("rememberMe")} />
                <div className="mx-auto ml-2">Remember me</div>
              </div>
              <button
                type="button"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot password ?
              </button>
            </div>

            <button
              className="button-yellow mt-8 w-full"
              type="submit"
              style={{
                backgroundColor: "#ffdd28",
              }}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
