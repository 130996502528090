import { AnalysisModel } from "@Features/dashboard/data/analysis.model";
import { GetAnalysisListUsecase } from "@Features/dashboard/domain/GetAnalysisList.usecase";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { StudyCaseSection } from "./AnalysisSubSection/StudyCase.component";
import { NoAccessSection } from "./NoAccessSection.component";
import { AiOutlineCloseCircle } from "react-icons/ai";

export const AnalysisSection = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState<AnalysisModel[]>([]);
  const [filterCode, setFilterCode] = useState("");
  const [loadMore, setLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [parameter, setParameter] = useState<any>({});

  useEffect(() => {
    if (filterCode != "") setAllDataLoaded(false);

    if (filterCode != "" && !isLoading)
      fetchAnalysis();
    else if (loadMore && !allDataLoaded)
      fetchAnalysis();

  }, [loadMore]);

  useEffect(() => {
    const onScroll = () => {
      if (loadMoreRef.current) {
        const rect = loadMoreRef.current.getBoundingClientRect();
        const shouldLoadMore = (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          parameter?.page < parameter?.end
        );

        if (shouldLoadMore) {
          setLoadMore(true);
        }
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [parameter]);

  const fetchAnalysis = async () => {
    try {
      setIsLoading(true);
      let response = await GetAnalysisListUsecase(currentPage, filterCode);
      let metaData = response.pop()

      let newApiData = filterCode == "" ? [
        ...apiData,
        ...response,
      ] : response;

      setParameter(metaData);
      setApiData(newApiData);
      setCurrentPage(currentPage + 1);
      setAllDataLoaded(response.length == 0 && filterCode == "");
    } catch (e) { }

    setIsLoading(false);
    setLoadMore(false);
  }

  const clearFilter = () => {
    setFilterCode("");
    setLoadMore(true);
    setCurrentPage(1);
    setApiData([])
    setParameter({})
  };

  return (
    <div className="mt-6">
      <Helmet encodeSpecialCharacters={true}>
        <title>Analisa Saham - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Analisa saham" />
      </Helmet>

      <div className="flex items-center gap-2">

        { filterCode?.length !== 0 &&
          <div className="text-red-500">
            <button 
              onClick={() => clearFilter()}
              disabled={isLoading}
            >
              <AiOutlineCloseCircle/>
            </button>
          </div>
        }
        <input
          className="form-input w-full md:w-1/4"
          type="text"
          placeholder="Cari Saham"
          value={filterCode}
          onChange={(e) => {
            setCurrentPage(1);
            setOnSearch(false);
            if (e.target.value.length === 0) {
              clearFilter();
            } else {
              setFilterCode(e.target.value);
            }
          }}
          disabled={isLoading}
        />
        
        <button
          className="button-yellow w-fit p-3 "
          onClick={()=>{
            fetchAnalysis();
            setOnSearch(true);
          }}
          style={{
            backgroundColor: "#ffdd28",
          }}
          disabled={onSearch || filterCode.length === 0}
        >
          Cari
        </button>
        
      </div>

      {
        (loadMore || apiData.length > 0) && (!isLoading || loadMore) && <>
          <StudyCaseSection
            data={apiData}
          />
          <div ref={loadMoreRef}></div>
        </>
      }

      {!isLoading && filterCode.length > 0 && apiData.length === 0 && <>
        <div className="py-10 text-center">
          <div className="text-white text-xl md:text-3xl lg:text-5xl font-bold">
            Data tidak tersedia.
          </div>
        </div>
      </>}

      {!isLoading && apiData.length === 0 && filterCode.length === 0 && !loadMore && <NoAccessSection />}

      {isLoading && <MidasShimmering />}
    </div>
  );
};
