import { AffiliateWithdrawModel } from "@Features/affiliate/data/affiliate-withdraw.model";
import {
  convertDateOnlyFromApiToView,
  formatToCurrency,
} from "@Libraries/utils/Formatter";
import { FC, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export const AffiliateWithdrawDetail: FC<AffiliateWithdrawModel> = ({
  affiliateName,
  amount,
  bank,
  createdAt,
  notes,
  status,
  statusClass,
  updatedAt,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="rounded shadow border bg-slate-100 px-4 py-2 my-2">
      <div
        className="flex justify-between"
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className="font-bold my-auto">{formatToCurrency(amount)}</div>
        <div className="flex">
          <div className={`py-1 px-3 ${statusClass} rounded font-bold text-xs`}>
            {status}
          </div>{" "}
          {isExpanded ? (
            <FaChevronUp size={16} className="my-auto ml-4" />
          ) : (
            <FaChevronDown size={16} className="my-auto ml-4" />
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="mt-4">
          <div className="flex justify-between">
            <div>Nama Affiliate</div>
            <div className="font-semibold">{affiliateName}</div>
          </div>
          {createdAt && (
            <div className="flex justify-between mt-2">
              <div>Tanggal Pengajuan</div>
              <div className="font-semibold">
                {convertDateOnlyFromApiToView(createdAt)}
              </div>
            </div>
          )}
          <div className="flex justify-between mt-2">
            <div>Jumlah</div>
            <div className="font-semibold">{formatToCurrency(amount)}</div>
          </div>
          <div className="flex justify-between mt-2">
            <div>Nama Bank</div>
            <div className="font-semibold">{bank?.bankName ?? "N/A"}</div>
          </div>
          <div className="flex justify-between mt-2">
            <div>Nama Pemilik Akun</div>
            <div className="font-semibold">{bank?.bankHolderName ?? "N/A"}</div>
          </div>
          <div className="flex justify-between mt-2">
            <div>Nomor Rekening</div>
            <div className="font-semibold">{`${bank?.bankAccountNumber.substring(
              0,
              2
            )}***********${bank?.bankAccountNumber.substring(
              bank?.bankAccountNumber.length - 2
            )}`}</div>
          </div>
          {updatedAt && (
            <div className="flex justify-between mt-2">
              <div>Tanggal Pembaruan</div>
              <div className="font-semibold">
                {convertDateOnlyFromApiToView(updatedAt)}
              </div>
            </div>
          )}
          {notes && (
            <div className="mt-2">
              <div>Catatan</div>
              <div className="font-semibold">{notes}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
