import {
  CarouselModel,
  LinearTextCarouselModel,
  TestimoniCarouselModel,
} from "./carousel.model";

export enum CarouselType {
  TESTIMONI,
  SHOWCASE,
  LINEAR,
  LINEAR_TEXT,
  LINEAR_TEASER,
}

export type CarouselItemModel =
  | CarouselModel
  | TestimoniCarouselModel
  | LinearTextCarouselModel;

export type CarouselItemListModel =
  | CarouselModel[]
  | TestimoniCarouselModel[]
  | LinearTextCarouselModel[];

export type CarouselListModel = {
  carouselId: string;
  carouselList: CarouselItemListModel;
  focusedIndex: number;
  type: CarouselType;
};
