import secureLocalStorage from "react-secure-storage";
import { CART_KEY } from "./SaveCartId.usecase";

export const DeleteCartId: () => Promise<boolean> = async () => {
  if (typeof Storage !== "undefined") {
    secureLocalStorage.removeItem(CART_KEY);
    return true;
  }

  return false;
};
