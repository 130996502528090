import { DividerComponent } from "@Libraries/components/divider/Divider.component";
import { useLocation, useNavigate } from "react-router-dom";
import { AffiliateAreaMenu } from "@Features/affiliate/data/affiliate-const";
import { DashboardSidebarItem } from "@Features/dashboard/presentation/components/DashboardSidebarItem.component";

export const AffiliateSidebarComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathList = location.pathname.split("/");

  return (
    <div className="h-fit">
      {/* Title */}
      <div className="p-4 lg:p-8 text-white font-bold text-xl overflow-hidden whitespace-nowrap">
        Dashboard Affiliate
      </div>

      <DividerComponent />

      <div className="hidden lg:block">
        {AffiliateAreaMenu.map((item, index) => (
          <DashboardSidebarItem
            key={`menu-affiliate-${index}`}
            label={item.label}
            icon={item.icon}
            isActive={
              pathList[pathList.length - 1].toLowerCase() === item.lastPath
            }
            isSpecialMenu={item.isSpecialMenu}
            destination={item.path}
          />
        ))}
      </div>

      <div className="block lg:hidden p-4">
        <select
          className="w-full h-10 px-4 font-bold text-white bg-2e2e2e"
          value={pathList[pathList.length - 1].toLowerCase()}
          onChange={(e) => {
            if (e.target.value !== undefined && e.target.value !== "") {
              let selected = AffiliateAreaMenu.filter(
                (item) => item.lastPath === e.target.value
              );
              navigate(selected[0].path);
            }
          }}
        >
          {AffiliateAreaMenu.map((item, index) => (
            <option key={`menu-affiliate-${index}`} value={item.lastPath}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
