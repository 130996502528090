import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { Helmet } from "react-helmet";

export const NoAccessSection = () => {
  return (
    <div className="mt-4 w-full h-full">
      <Helmet encodeSpecialCharacters={true}>
        <title>No Access - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - No Access" />
      </Helmet>

      <div className="py-10 text-center">
        <div className="text-white text-xl md:text-3xl lg:text-5xl font-bold">
          Maaf, Akun Anda belum punya akses ke fitur ini.
        </div>
        <MidasRoundedYellowButton label="Upgrade Akun" isExternalLink={false} urlDestination="/member-area/upgrade" additionalClass="mt-10 py-4 px-10 font-bold text-xl" />
      </div>
    </div>
  );
};