import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { CartProductModel } from "../data/cart.model";

export const GetPriceUsecase: (
  productId: string
) => Promise<CartProductModel> = async (productId) => {
  try {
    let response = await client.get(
      ApiConst.API_PRODUCT + "/getPrice/" + productId
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
