import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { useEffect, useState } from "react";
import { GiClick } from "react-icons/gi";
import { TrackerDetailComponent } from "../components/TrackerDetail.component";
import { FaCartPlus, FaMoneyBill, FaPercent } from "react-icons/fa";
import { BsCartCheckFill } from "react-icons/bs";
import { GetAffiliateDashboardUsecase } from "@Features/affiliate/domain/GetAffiliateDashboard.usecase";
import { AffiliateDashboardModel } from "@Features/affiliate/data/affiliate-dashboard.model";
import { formatToCurrency } from "@Libraries/utils/Formatter";
import { GetTrackerListUseCase } from "@Features/affiliate/domain/GetTrackerList.usecase";
import { AffiliateTrackerListModel } from "@Features/affiliate/data/affiliate-tracker-list.model";
import { TableComponent } from "@Features/dashboard/presentation/components/Table.component";
import { AffiliateGenerateUrlModal } from "../components/AffiliateGenerateUrlModal.component";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import { ToasterPosition } from "@Libraries/components/toast/Toast.model";

export const AffiliateMainDashboardSection = () => {
  const toasterContext = UseToasterContext();

  const [isLoading, setIsLoading] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [dashboardData, setDashboardData] = useState<AffiliateDashboardModel>();
  const [trackerList, setTrackerList] = useState<AffiliateTrackerListModel[]>();

  const [showGenerateUrlModal, setShowGenerateUrlModal] = useState(false);

  useEffect(() => {
    if (needRefresh) {
      setIsLoading(true);
      fetchDashboardData();
      fetchTrackerListData();
      setNeedRefresh(false);
    }
  }, [needRefresh]);

  const fetchDashboardData = async () => {
    let data = await GetAffiliateDashboardUsecase();
    setDashboardData(data);
    setIsLoading(false);
  };

  const fetchTrackerListData = async () => {
    let data = await GetTrackerListUseCase();
    setTrackerList(data);
  };

  const onGenerateUrlClicked = () => {
    setShowGenerateUrlModal(true);
  };

  const onDismissGenerateUrl = () => {
    setShowGenerateUrlModal(false);
  };

  return (
    <div>
      {isLoading && <MidasShimmering />}

      {!isLoading && dashboardData && (
        <div>
          <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Total Earnings"
              subtitle={formatToCurrency(dashboardData.earnings.totalEarnings)}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Current Balance"
              subtitle={formatToCurrency(dashboardData.earnings.currentBalance)}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="Withdrawn Balance"
              subtitle={formatToCurrency(
                dashboardData.earnings.withdrawnBalance
              )}
            />
            <TrackerDetailComponent
              icon={
                <FaMoneyBill size={48} className="color-brand-yellow my-auto" />
              }
              title="On Hold Balance"
              subtitle={formatToCurrency(dashboardData.earnings.onHoldBalance)}
            />
          </div>

          <div className="mt-6 text-white font-bold text-lg">Last 30 Days</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <TrackerDetailComponent
              icon={
                <GiClick size={32} className="color-brand-yellow my-auto" />
              }
              title="Visits"
              subtitle={dashboardData.last30Days.visits.toString()}
              changes={dashboardData.last30Days.visitChanges}
            />
            <TrackerDetailComponent
              icon={
                <FaCartPlus size={32} className="color-brand-yellow my-auto" />
              }
              title="Add to Cart"
              subtitle={dashboardData.last30Days.atc.toString()}
              changes={dashboardData.last30Days.atcChanges}
            />
            <TrackerDetailComponent
              icon={
                <BsCartCheckFill
                  size={32}
                  className="color-brand-yellow my-auto"
                />
              }
              title="Checkout"
              subtitle={dashboardData.last30Days.complete.toString()}
              changes={dashboardData.last30Days.completeChanges}
            />
          </div>

          <div className="mt-6 text-white font-bold text-lg">All Times</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <TrackerDetailComponent
              icon={
                <GiClick size={32} className="color-brand-yellow my-auto" />
              }
              title="Visits"
              subtitle={dashboardData.allTimes.visits.toString()}
            />
            <TrackerDetailComponent
              icon={
                <FaCartPlus size={32} className="color-brand-yellow my-auto" />
              }
              title="Add to Cart"
              subtitle={dashboardData.allTimes.atc.toString()}
            />
            <TrackerDetailComponent
              icon={
                <BsCartCheckFill
                  size={32}
                  className="color-brand-yellow my-auto"
                />
              }
              title="Checkout"
              subtitle={dashboardData.allTimes.complete.toString()}
            />
            <TrackerDetailComponent
              icon={
                <FaPercent size={32} className="color-brand-yellow my-auto" />
              }
              title="Convertion Rate"
              subtitle={dashboardData.allTimes.convertion.toFixed(2) + " %"}
            />
          </div>

          <button
            className="mt-10 border border-brand-yellow rounded-lg text-[#ffdd28] w-full px-5 py-2 d:px-6 md:py-2 text-sm font-bold hover:color-yellow hover:text-black"
            type="button"
            onClick={onGenerateUrlClicked}
          >
            Generate URL
          </button>

          <div className="h-4" />
          {trackerList && (
            <TableComponent
              title="List of Trackers Data"
              label={["URL", "Visits Count"]}
              data={trackerList?.map((item) => [
                item.url,
                item.count.toString(),
              ])}
              freezeColumn={false}
              copiable={true}
            />
          )}
        </div>
      )}

      {showGenerateUrlModal && (
        <AffiliateGenerateUrlModal
          affiliateName={dashboardData?.affiliateName ?? ""}
          onDismiss={onDismissGenerateUrl}
        />
      )}

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
