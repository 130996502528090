import { FooterComponent } from "@Libraries/components/footers/Footer.component";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export const BasePage: FC = () => {
  return (
    <div className="flex flex-col bg-sidebar">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <Outlet />
      <FooterComponent />
    </div>
  );
};
