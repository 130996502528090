import { Tabs } from "@Libraries/components/tab/Tab.component";
import { FC, useState } from "react";
import { TableComponent, TableProps } from "./Table.component";

export type JarvisCalculationProps = {
  indicatorData: TableProps;
  debtData: TableProps;
  eficiencyData: TableProps;
  ratioData: TableProps;
};

export const JarvisCalculationComponent: FC<JarvisCalculationProps> = ({
  debtData,
  eficiencyData,
  indicatorData,
  ratioData,
}) => {
  const tabsItemList = ["Indikator", "Hutang", "Efisiensi", "Ratio"];

  const [selectedTab, setSelectedTab] = useState(0);

  const onChangeTab = (newIndex: number) => {
    setSelectedTab(newIndex);
  };

  const getTableProps: () => TableProps = () => {
    if (selectedTab === 0) return indicatorData;

    if (selectedTab === 1) return debtData;

    if (selectedTab === 2) return eficiencyData;

    return ratioData;
  };

  return (
    <div>
      <Tabs
        tabItems={tabsItemList}
        selectedIndex={selectedTab}
        onClickTab={onChangeTab}
      />
      <div className="w-full border border-white">
        <TableComponent {...getTableProps()} />
      </div>
    </div>
  );
};
