import {
  AffiliateLandingPageModel,
  AffiliateProductModel,
} from "@Features/affiliate/data/affiliate-product.model";
import { GetAffiliateLandingPagesUsecase } from "@Features/affiliate/domain/GetAffiliateLandingPages.usecase";
import { GetAffiliateProductListUseCase } from "@Features/affiliate/domain/GetAffiliateProductList.usecase";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import {
  ToasterModel,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";

export type AffiliateGenerateUrlModalProps = {
  affiliateName: string;
  onDismiss: () => void;
};

export type AffiliateGenerateUrlModalInputs = {
  type: string;
  productId: string;
  slug: string;
};

export const AffiliateGenerateUrlModal: FC<AffiliateGenerateUrlModalProps> = ({
  affiliateName,
  onDismiss,
}) => {
  const midasMainUrl = "https://midascuan.id/";

  const toasterContext = UseToasterContext();

  const { register, watch } = useForm<AffiliateGenerateUrlModalInputs>();

  const [showLoading, setShowLoading] = useState(false);
  const [showNestedLoading, setShowNestedLoading] = useState(false);
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const [showSelectPage, setShowSelectPage] = useState(false);

  const [availableProductList, setAvailableProductList] =
    useState<AffiliateProductModel[]>();
  const [availableLandingPages, setAvailableLandingPages] =
    useState<AffiliateLandingPageModel[]>();

  const [canGenerate, setCanGenerate] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState<string>();

  useEffect(() => {
    if (affiliateName === "") {
      onDismiss();
      return;
    }

    async function fetchAffiliateProductList() {
      let data = await GetAffiliateProductListUseCase();
      setAvailableProductList(data);
      setShowLoading(false);
    }

    async function fetchAffiliateLandingPageList() {
      let data = await GetAffiliateLandingPagesUsecase();
      setAvailableLandingPages(data);
      setShowLoading(false);
    }

    setShowLoading(true);
    fetchAffiliateProductList();
    fetchAffiliateLandingPageList();
  }, []);

  const onGenerateUrl = () => {
    setShowNestedLoading(true);

    let type = watch("type");
    let productId = watch("productId");
    let page = watch("slug");

    switch (type) {
      case "home":
        setGeneratedUrl(`${midasMainUrl}?aff_id=${affiliateName}`);
        break;
      case "atc":
        if (!productId || productId === "") break;
        setGeneratedUrl(
          `${midasMainUrl}add-to-cart?product=${productId}&aff_id=${affiliateName}`
        );
        break;
      case "landing":
        if (!page || page === "") break;
        setGeneratedUrl(`${midasMainUrl}${page}?aff_id=${affiliateName}`);
        break;
    }

    setShowNestedLoading(false);
  };

  function filterLP(page: string) {
    return page !== "Thankyou Page" && page !== "Special_Offer" && page !== "Midas Cuan"
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen w-screen">
      <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <h3 className="text-lg leading-6 font-bold text-gray-900 flex justify-between">
          <div>Generate Affiliate URL</div>
          <AiOutlineCloseCircle color="red" size={24} onClick={onDismiss} />
        </h3>
        <div className="mt-2 pb-3">
          {!showLoading && (
            <div>
              <div className="flex w-full gap-4">
                <div className="my-auto w-full">
                  <select
                    className="form-input w-full"
                    {...register("type", {
                      onChange: (event) => {
                        if (event.target.value === "atc") {
                          setShowSelectProduct(true);
                          setShowSelectPage(false);
                          setCanGenerate(false);
                        } else if (event.target.value === "landing") {
                          setShowSelectProduct(false);
                          setShowSelectPage(true);
                          setCanGenerate(false);
                        } else {
                          setShowSelectProduct(false);
                          setShowSelectPage(false);
                          setCanGenerate(true);
                        }
                        setGeneratedUrl("");
                      },
                    })}
                  >
                    <option value="" selected>-- Pilih Jenis URL --</option>
                    <option value="home">Homepage</option>
                    <option value="landing">Landing Pages</option>
                    <option value="atc">Tambah Produk ke Keranjang</option>
                  </select>
                </div>

                {showSelectProduct && (
                  <div className="my-auto w-full">
                    <select
                      className="form-input w-full"
                      {...register("productId", {
                        onChange: (event) => {
                          if (event.target.value !== "") {
                            setCanGenerate(true);
                          } else {
                            setCanGenerate(false);
                          }
                          setGeneratedUrl("");
                        },
                      })}
                    >
                      <option value="" selected>-- Pilih Product --</option>
                      {availableProductList?.map((item) => (
                        <option
                          key={`${item.id}-${item.title}`}
                          value={item.id}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {showSelectPage && (
                  <div className="my-auto w-full">
                    <select
                      className="form-input w-full"
                      {...register("slug", {
                        onChange: (event) => {
                          if (event.target.value !== "") {
                            setCanGenerate(true);
                          } else {
                            setCanGenerate(false);
                          }
                          setGeneratedUrl("");
                        },
                      })}
                    >
                      <option value="" selected>-- Pilih Landing Page --</option>
                      {availableLandingPages?.filter((item) => filterLP(item.title))?.map((item) => (
                        <option
                          key={`${item.slug}-${item.title}`}
                          value={item.slug}
                        >
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              {canGenerate && (
                <button
                  className="mt-4 border border-brand-yellow rounded-lg text-[#ffdd28] w-full px-5 py-2 d:px-6 md:py-2 text-sm font-bold hover:color-yellow hover:text-black"
                  type="button"
                  onClick={onGenerateUrl}
                >
                  Generate URL
                </button>
              )}
            </div>
          )}

          {(showLoading || showNestedLoading) && <MidasShimmering />}
          {!showLoading &&
            !showNestedLoading &&
            generatedUrl &&
            generatedUrl !== "" && (
              <div className="flex mt-4 w-full justify-center font-bold text-xl gap-4">
                <div>{generatedUrl}</div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(generatedUrl);
                    toasterContext.setToastList([
                      ...toasterContext.toastList,
                      new ToasterModel(
                        "Berhasil menyalin URL",
                        "",
                        ToasterType.SUCCESS
                      ),
                    ]);
                    onDismiss();
                  }}
                >
                  <FaCopy size={24} />
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
