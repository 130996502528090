import {
  LoadPageSettingUsecase,
  PageSettingListModel,
} from "@Features/common/domain/LoadPageSettings.usecase";
import {
  STOCK_TREASURE_MAPS_TOOLS,
  STOCK_PORTOFOLIO_TOOLS
} from "@Libraries/utils/Const";

export const GetToolsListUsecase: () => Promise<PageSettingListModel> =
  async () => {
    let optionKeys = `${STOCK_TREASURE_MAPS_TOOLS};${STOCK_PORTOFOLIO_TOOLS}`;
    return await LoadPageSettingUsecase(optionKeys);
  };
