import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const RemoveVoucherUsecase: (
  orderId: string
) => Promise<boolean> = async (orderId) => {
  try {
    let formData = new FormData();
    formData.append("order_id", orderId);

    await client.post(ApiConst.API_REMOVE_VOUCHER, formData);
    return true;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
