import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AffiliateTrackerListModel } from "../data/affiliate-tracker-list.model";

export const GetTrackerListUseCase: () => Promise<
  AffiliateTrackerListModel[]
> = async () => {
  try {
    let result: AffiliateTrackerListModel[] = [];

    let response = await client.get(ApiConst.API_AFFILIATES + "/trackerList");
    let data = response.data.data;

    for (let key in data) {
      result.push({
        url: key,
        count: data[key],
      });
    }

    return result;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
