import { GetWatchlistListUsecase } from "@Features/dashboard/domain/GetWatchlistList.usecase";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NoAccessSection } from "../NoAccessSection.component";
import { Helmet } from "react-helmet";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";

export type WatchlistMonthYearModel = {
  title: string;
  emitenList: WatchlistMapModel;
}

export type WatchlistMapModel = {
  [key: string]: WatchlistDetailModel;
}

export type WatchlistDetailModel = {
  code: string;
  url: string;
  image: string;
  name: string;
}

export type WatchlistSectionProps = WatchlistMonthYearModel & {
  data: any
};

export const WatchlistSection: FC= () => {
  const [watchlistData, setWatchlistData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchWatchlist() {
      setIsLoading(true);
      try {
        let response = await GetWatchlistListUsecase();
        setWatchlistData(response);
      } catch (e) { }
      setIsLoading(false);
    }
    fetchWatchlist()
  },[])

   return (
    <div className="mt-6">
      <Helmet encodeSpecialCharacters={true}>
        <title>Midas Watchlist - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Midas Watchlist" />
      </Helmet>
      {isLoading && <MidasShimmering />}
      {!isLoading && (
        <>
          { watchlistData?.length !== 0 ? 
            <div>
              {Object.keys(watchlistData).reverse().map((key, index) => {
                let emitenData = watchlistData[key];

                let indexing = index;

                let detailData :{
                  code: string,
                  url: string,
                  img: string,
                }[]=[]

                let url = "member-area/watchlist";
                
                Object.keys(emitenData.emitenList).map((key,index) => {
                  let detailList = emitenData.emitenList[key]
                  return (
                    detailData.push({
                      code: detailList.code,
                      url: detailList.url,
                      img: detailList.image}
                    ),
                    url = detailList.url
                  )
                })

                return (
                  <Link to={`/${url}`}>
                  <div className="mb-4">
                    <div className="flex flex-wrap items-center  gap-4 rounded-3xl w-full bg-black p-4 text-white relative">
                      <div className="mb-2 break-words text-center color-brand-yellow text-sm lg:text-2xl font-extrabold">
                        {indexing+1 == 1  ? `${emitenData.title} (NEW)` : emitenData.title} 
                      </div>
                      {detailData.map((item, index) => (
                        <Link
                          to={`/${item.url}`}
                        >
                          <img
                            src={item.img}
                            className="object-cover w-12 h-12 lg:w-24 lg:h-24 bold"
                            alt={`Analisa Saham ${item.code}`}
                          />
                          <div className="text-center mt-2 text-sm lg:text-lg font-bold color-brand-yellow">
                          {item.code}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  </Link>
                )
              })}
            </div>
            : <NoAccessSection/>
          }
        </>
      )}
    </div>
  );
};
