import { Bars3 } from "@Assets/common.assets";
import { MidasLogo } from "@Assets/midas-logo";
import { UserSession } from "@Libraries/users/UserSession";
import { FC, useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { MidasRoundedYellowButton } from "@Libraries/components/buttons/MidasRoundedYellowButton.component";
import { FetchEbookURLSettingsUsecase } from "@Features/homepage/domain/FetchEbookurlSettings.usecase";
import { EBOOK_URL } from "@Libraries/utils/Const";

export type MenuItem = {
  text: string;
  destination: string;
};

export type MenuItemList = MenuItem & {
  submenus: MenuItem[];
};

export type HeaderWithMenuProps = {
  menuItems: MenuItemList[];
  shouldShowLoginButton: boolean;
};

export const HeaderWithMenu: FC<HeaderWithMenuProps> = ({
  menuItems,
  shouldShowLoginButton,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ebookUrl, setEbookUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    runAdditionalMenuSetup();
  }, []);

  const runAdditionalMenuSetup = async () => {
    try {
      let response = await FetchEbookURLSettingsUsecase();
      setEbookUrl(response[EBOOK_URL]);
    } catch (error) {
      setEbookUrl("");
    }
  }

  return (
    <nav className="flex w-full bg-midas-neutral-900 justify-between text-white mx-auto items-center">
      <Link to={"/"} className="my-auto ml-4 lg:ml-14">
        <MidasLogo className={""} />
      </Link>

      <div className="flex my-auto mr-4 lg:mr-14 lg:order-2">
        {shouldShowLoginButton &&
          (UserSession.checkIsLoggedIn() ? (
            <Link to={"/profile"} className="flex justify-center text-white">
              <div className="my-auto">
                {UserSession.getPicture() !== null &&
                  UserSession.getPicture() !== "" ? (
                  <img
                    src={UserSession.getPicture()}
                    alt={`Profile ${UserSession.getEmail()}`}
                    className="w-6 h-6 object-cover rounded-full"
                  />
                ) : (
                  <FaRegUserCircle size={24} />
                )}
              </div>
              <div className="hidden mx-2 text-sm font-bold overflow-ellipsis my-auto lg:block">
                {UserSession.getName()}
              </div>
            </Link>
          ) : (
            <MidasRoundedYellowButton
              label="Masuk"
              onClick={() => navigate("/login")}
              isExternalLink={false}
            />
          ))}
        <button
          className="ml-4 w-6 h-6 my-auto bg-black lg:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Bars3 />
        </button>
      </div>

      <div
        className={`${isMenuOpen ? "active" : "inactive"
          } responsive-nav-menu md:order-1`}
      >
        {menuItems.map((menu, index) =>
          menu.submenus.length > 0 ? (
            <div key={`nav-menu-${index}`} className="dropdown relative">
              <button
                className="px-4  py-2.5   text-white    font-bold    text-sm    rounded    shadow-md    transition    duration-150    ease-in-out    flex    items-center    whitespace-nowrap dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {menu.text}
                {/* <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="caret-down"
                  className="w-2 ml-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg> */}
              </button>
              <ul
                className="dropdown-menu min-w-max absolute hidden bg-sidebar text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none "
                aria-labelledby="dropdownMenuButton1"
              >
                {menu.submenus.map((submenuItem, subIndex) => (
                  <li key={`submenu-${index}-${subIndex}`}>
                    <Link
                      to={submenuItem.destination.replaceAll("${ebookURL}", ebookUrl)}
                      className="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap text-white  hover:bg-gray-500 opacity-100"
                    >
                      {submenuItem.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <Link
              to={menu.destination.replaceAll("${ebookURL}", ebookUrl)}
              key={`nav-menu-${index}`}
              className="midas-menu-item  hover:text-white"
            >
              {menu.text}
            </Link>
          )
        )}
      </div>
    </nav>
  );
};
