import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { AffiliateBankModel } from "../data/affiliate-bank.model";

export const GetBankListUseCase: () => Promise<
  AffiliateBankModel[]
> = async () => {
  try {
    let response = await client.get(ApiConst.API_AFFILIATES + "/bankList");
    let data = response.data.data;

    let returnData = data.map((item: any) => ({
      affiliateName: item.affiliate_name,
      bankAccountNumber: item.bank_account_number,
      bankHolderName: item.bank_holder_name,
      bankName: item.bank_name,
      createdAt: item.created_at,
      id: item.id,
      updatedAt: item.updated_at,
      userId: item.user_id,
    }));

    return returnData;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
