import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const ForgotPasswordUsecase: (email: string) => Promise<string> = async (
  email
) => {
  try {
    let response = await client.post(ApiConst.API_FORGOT_PASSWORD, {
      email: email,
    });
    return response.data.message;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
