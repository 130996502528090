/* eslint-disable react-hooks/exhaustive-deps */
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { Toaster } from "@Libraries/components/toast/Toast.component";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { GetProfileUsecase } from "../domain/GetProfile.usecase";
import { ProfileUiModel } from "../domain/model/Profile.ui.model";
import { ChangePasswordSectionComponent } from "./components/ChangePasswordSection.component";
import { MembershipSectionComponent } from "./components/MembershipSection.component";
import { ProfileSectionComponent } from "./components/ProfileSection.component";
import { SidebarComponent } from "./components/Sidebar.component";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export enum ProfileSection {
  PROFILE,
  CHANGE_PASSWORD,
  MEMBERSHIP,
  ORDERLIST,
  LOGOUT,
}

export const ProfilePage: FC = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const toasterContext = UseToasterContext();

  const [needRefresh, setNeedRefresh] = useState(true);
  const [networkData, setNetworkData] = useState<ProfileUiModel | null>(null);
  const [currentActiveSection, setCurrentActiveSection] =
    useState<ProfileSection>(ProfileSection.PROFILE);

  useEffect(() => {
    if (needRefresh) {
      GetProfileUsecase.execute()
        .then((response) => {
          setNetworkData(response);
          setCurrentActiveSection(getSectionBasedOnHash());
        })
        .catch((error) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch profile!",
              error,
              ToasterType.DANGER
            ),
          ]);
          navigate("/login");
        });
      setNeedRefresh(false);
    }
  }, [needRefresh]);

  const getSectionBasedOnHash: () => ProfileSection = () => {
    switch (hash) {
      case "#order":
        return ProfileSection.ORDERLIST;
      case "#member":
        return ProfileSection.MEMBERSHIP;
      case "#password":
        return ProfileSection.CHANGE_PASSWORD;
      default:
        return ProfileSection.PROFILE;
    }
  };

  const onChangeSection: (newSection: ProfileSection) => void = (
    newSection
  ) => {
    setCurrentActiveSection(newSection);
  };

  const onLogout = async () => {
    UserSession.logout();
    navigate("/login");
  };

  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll">
      <Helmet encodeSpecialCharacters={true}>
        <title>Profile - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Profile" />
      </Helmet>

      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <div className="mt-6 mx-auto md:mt-10 lg:mt-14 text-white w-fit grid grid-cols-1 justify-center gap-10 lg:grid-cols-3">
        <SidebarComponent
          networkData={networkData}
          activeSection={currentActiveSection}
          onChangeSection={onChangeSection}
          onLogout={onLogout}
          setNeedRefresh={setNeedRefresh}
        />
        <div className="user-profile-container mr-0 lg:mr-14 col-span-2">
          {networkData !== null ? (
            currentActiveSection === ProfileSection.PROFILE ? (
              <ProfileSectionComponent
                {...networkData}
                setNeedRefresh={setNeedRefresh}
                onChangePasswordClicked={() =>
                  onChangeSection(ProfileSection.CHANGE_PASSWORD)
                }
              />
            ) : currentActiveSection === ProfileSection.CHANGE_PASSWORD ? (
              <ChangePasswordSectionComponent />
            ) : currentActiveSection === ProfileSection.MEMBERSHIP ? (
              <MembershipSectionComponent
                memberRoles={networkData.memberActiveRoles}
              />
            ) : (
              <div className="w-96 font-bold text-white text-center">
                Coming Soon
              </div>
            )
          ) : (
            <div className="w-96 font-bold text-white text-center">
              Coming Soon
            </div>
          )}
        </div>
      </div>

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
      <WaButtonFloating/>
    </div>
  );
};
