import { LoadPageSettingUsecase } from "@Features/common/domain/LoadPageSettings.usecase";
import {
  PHILOSOPHY_CRITERIA,
  PHILOSOPHY_TOP_HEADER,
} from "@Libraries/utils/Const";

export const FetchInvestmentPhilosophySettingsUsecase = async () => {
  let optionKeys = `${PHILOSOPHY_CRITERIA};${PHILOSOPHY_TOP_HEADER}`;
  return await LoadPageSettingUsecase(optionKeys);
};
