import { FC } from "react";

export type PriceDetailModel = {
  label: string;
  price: string;
  isBold: boolean;
  isBig: boolean;
  customClass?: string;
};

export const PriceDetail: FC<PriceDetailModel> = ({
  label,
  price,
  isBold,
  isBig,
  customClass,
}) => {
  return (
    <div className={`flex justify-between ${customClass || ""}`}>
      {/* label */}
      <div className="color-2d2d2d text-base text-left">{label}</div>

      {/* price */}
      <div
        className={`color-2d2d2d ${
          isBig ? "text-xl" : "text-base"
        } text-right ${isBold && "font-bold"} `}
      >
        {price}
      </div>
    </div>
  );
};
