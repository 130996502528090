/* eslint-disable react-hooks/exhaustive-deps */
import { FooterComponent } from "@Libraries/components/footers/Footer.component";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { ValidateAffiliateNameUseCase } from "../domain/ValidateAffiliateName.usecase";
import { UserSession } from "@Libraries/users/UserSession";
import { useNavigate } from "react-router-dom";
import { CheckIsAffiliateUsecase } from "../domain/CheckIsAffiliate.usecase";
import { UseToasterContext } from "@Features/common/contexts/Toaster.context";
import {
  ToasterModel,
  ToasterPosition,
  ToasterType,
} from "@Libraries/components/toast/Toast.model";
import { RegisterAffiliateUsecase } from "../domain/RegisterAffiliate.usecase";
import { Toaster } from "@Libraries/components/toast/Toast.component";

export type RegisterAffiliateFormInputs = {
  name: string;
  bankName: string;
  bankAccountNumber: string;
  bankAccountName: string;
};

export const RegisterAffiliatePage: FC = () => {
  const minNameLength = 6;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterAffiliateFormInputs>();
  const toasterContext = UseToasterContext();

  const [isUniqueNameValid, setIsUniqueNameValid] = useState<boolean>();

  useEffect(() => {
    if (!UserSession.checkIsLoggedIn()) {
      navigate("/login");
      return;
    }
    if (!UserSession.isUserCanAccessMemberArea()) {
      navigate("/login");
      return;
    }

    async function checkIsAffiliate() {
      let result = await CheckIsAffiliateUsecase();
      if (result === true) {
        navigate("/affiliate");
        return;
      }
    }

    checkIsAffiliate();
  }, []);

  const onNameChanged = async (e: any) => {
    let affiliateName = e.target.value;
    if (affiliateName.length >= minNameLength) {
      setIsUniqueNameValid(await ValidateAffiliateNameUseCase(affiliateName));
    }
  };

  const onSubmit = async (data: RegisterAffiliateFormInputs) => {
    try {
      let message = await RegisterAffiliateUsecase(
        data.name,
        data.bankAccountName,
        data.bankAccountNumber,
        data.bankName
      );
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(message, "", ToasterType.SUCCESS),
      ]);
      navigate("/affiliate");
    } catch (e) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(e.message, "", ToasterType.DANGER),
      ]);
    }
  };

  return (
    <div className="w-screen bg-2e2e2e">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <div>
        <div className="w-screen bg-gray-500 p-4 md:p-20">
          <form
            className="m-auto rounded shadow bg-white p-4 max-w-3xl"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="text-2xl font-bold text-center">
              Pendaftaran Affiliate
            </div>

            <div className="text-form-label mt-6">Affiliate Unique Name</div>
            <div className="relative">
              <input
                type="text"
                className="form-input mt-3"
                placeholder="Enter your unique affiliate name"
                {...register("name", {
                  required: "Unique Name is required",
                  min: minNameLength,
                  onChange: onNameChanged,
                })}
              />
              {isUniqueNameValid !== undefined && (
                <div className="absolute right-4 top-[40%]">
                  {isUniqueNameValid === true ? (
                    <FaCheckCircle size={24} className="text-green-500" />
                  ) : (
                    <FaTimesCircle size={24} className="text-red-500" />
                  )}
                </div>
              )}
            </div>

            {isUniqueNameValid && (
              <div>
                <div className="border-t w-full my-4"></div>

                <div className="text-form-label mt-6">Bank Account Name</div>
                <input
                  type="text"
                  className="form-input mt-3"
                  placeholder="Enter your bank account name"
                  {...register("bankAccountName", {
                    required: "Bank Account Name is required",
                  })}
                />
                {errors.bankAccountName && (
                  <p className="text-red-500 text-xs">
                    {errors.bankAccountName?.message}
                  </p>
                )}

                <div className="text-form-label mt-6">Bank Account Number</div>
                <input
                  type="number"
                  className="form-input mt-3"
                  placeholder="Enter your bank account number"
                  {...register("bankAccountNumber", {
                    required: "Bank Account Number is required",
                  })}
                />
                {errors.bankAccountNumber && (
                  <p className="text-red-500 text-xs">
                    {errors.bankAccountNumber?.message}
                  </p>
                )}

                <div className="text-form-label mt-6">Bank Name</div>
                <select
                  className="form-input mt-3"
                  {...register("bankName", {
                    required: "Bank Name is required",
                  })}
                >
                  <option value="" selected>-- Pilih Bank --</option>
                  <option value="BCA">Bank Central Asia (BCA)</option>
                  <option value="Mandiri">Bank Mandiri</option>
                  <option value="BRI">Bank Rakyat Indonesia (BRI)</option>
                  <option value="BNI">Bank Negara Indonesia (BNI)</option>
                  <option value="BSI">Bank Syariah Indonesia (BSI)</option>
                </select>
                {errors.bankName && (
                  <p className="text-red-500 text-xs">
                    {errors.bankName?.message}
                  </p>
                )}

                <button
                  onClick={handleSubmit(onSubmit)}
                  className="button-yellow my-4 w-full"
                >
                  Daftar
                </button>
              </div>
            )}
          </form>
        </div>
        <FooterComponent />
      </div>

      {toasterContext.toastList.length !== 0 && (
        <Toaster
          toastItems={toasterContext.toastList}
          isAutoDelete={true}
          position={ToasterPosition.BOTTOM_RIGHT}
          autoDeleteTimeInMilis={2000}
        />
      )}
    </div>
  );
};
