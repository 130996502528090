import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { ProfileModel } from "../data/Profile.model";
import { ProfileUiModel } from "./model/Profile.ui.model";

export const GetProfileUsecase = (function () {
  const execute: () => Promise<ProfileUiModel> = async () => {
    try {
      let response = await client.get(ApiConst.API_PROFILE);
      let data: ProfileModel = response.data.data;

      return {
        email: data.email,
        fullname: data.fullname,
        id: data.id,
        role: data.role,
        birthdate: data.birthdate,
        description: data.description,
        memberExpiredDate: data.member_expired_date,
        phone: data.phone,
        pictureUrl: data.picture_url,
        telegram: data.telegram,
        additionalInfo: data.additional_info,
        memberActiveRoles: data.member_active_roles.map((itemRole) => {
          return {
            ...itemRole,
            role: {
              ...itemRole.role,
              accessArray: itemRole.role.access.split(";"),
            },
          };
        }),
        memberRoles: data.member_roles,
      };
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  };

  return {
    execute: execute,
  };
})();
