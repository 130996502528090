import { MidasLogo } from "@Assets/midas-logo";

export const MidasShimmering = () => {
  return (
    <div className="flex justify-center w-full">
      <div className="animate-pulse">
        <MidasLogo className="" />
      </div>
    </div>
  );
};
