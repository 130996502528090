import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";
import { PageModel } from "../data/Page.model";

export const LoadPageUsecase: (slug: string) => Promise<PageModel> = async (
  slug
) => {
  try {
    let response = await client.get(ApiConst.API_PAGE + "/load/" + slug);
    let data = response.data.data;

    let resultData: PageModel = {
      id: data.id,
      title: data.title,
      slug: data.slug,
      type: data.type,
      status: data.status,
      content_html: data.content_html,
      content_json: data.content_json,
      is_show_header: data.is_show_header === "1" || data.is_show_header === 1,
      header_type: data.header_type,
      is_show_footer: data.is_show_footer === "1" || data.is_show_footer === 1,
      footer_type: data.footer_type,
      visibility: data.visibility,
      created_at: data.created_at,
      created_by: data.created_by,
      updated_at: data.updated_at,
      updated_by: data.updated_by,
    };

    return resultData;
  } catch (e) {
    throw new Error(e.response.status.toString());
  }
};
