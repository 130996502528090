import { ImageProps, MidasImage } from "../components/widgets/Image.component";

export class ImageModel {
  componentKey: string;
  imageUrl: string;
  width: number | null;
  widthPercentage: number;
  height: number | null;
  objectFit: string;
  altText: string;

  constructor(
    componentKey: string,
    imageUrl?: string,
    width?: number | null,
    widthPercentage?: number,
    height?: number | null,
    objectFit?: string,
    altText?: string
  ) {
    this.componentKey = componentKey;
    this.imageUrl = imageUrl ?? "";
    this.width = width ?? null;
    this.widthPercentage = widthPercentage ?? 50;
    this.height = height ?? null;
    this.objectFit = objectFit ?? "cover";
    this.altText = altText ?? "";
  }

  render() {
    return (
      <MidasImage
        componentKey={this.componentKey}
        imageUrl={this.imageUrl}
        altText={this.altText}
        options={{
          width: this.width,
          widthPercentage: window.screen.width <= 500 ? 100 : this.widthPercentage,
          height: this.height,
          objectFit: this.objectFit,
        }}
      />
    );
  }

  getJsonString() {
    return {
      type: "image",
      imageUrl: this.imageUrl,
      altText: this.altText,
      options: {
        width: this.width,
        widthPercentage: this.widthPercentage,
        height: this.height,
        objectFit: this.objectFit,
      },
    };
  }

  jsonToModel(data: ImageProps) {
    this.imageUrl = data.imageUrl;
    this.width = data.options.width;
    this.widthPercentage = data.options.widthPercentage;
    this.height = data.options.height;
    this.objectFit = data.options.objectFit;
    this.altText = data.altText;
  }
}
