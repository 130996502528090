import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const GetStockDetailUsecase: (
  period: string,
  code: string
) => Promise<any> = async (period, code) => {
  try {
    let response = await client.get(ApiConst.API_JARVIS + `/${period}/${code}`);
    let data = response.data;
    return data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
