import { FC } from "react";

export type SpacerOptions = {
  width: number;
  widthPercentage: number;
  height: number;
};

export type SpacerProps = {
  componentKey: string;
  options: SpacerOptions;
};

export const MidasSpacer: FC<SpacerProps> = ({ componentKey, options }) => {
  let width = "";
  if (options.width > 0) {
    width = options.width + "px";
  } else {
    width = options.widthPercentage + "%";
  }

  return (
    <div
      key={componentKey}
      className="m-auto"
      style={{
        width: width,
        height: options.height + "px",
      }}
    />
  );
};
