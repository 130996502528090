import { FC, useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { TestimoniCarouselComponent } from "./items/TestimoniCarousel.component";
import {
  CarouselItemModel,
  CarouselListModel,
  CarouselType,
} from "./models/carousel-list.model";
import { TestimoniCarouselModel } from "./models/carousel.model";

export type ShowcaseCarouselProps = CarouselListModel & {
  onClick?: (model: CarouselItemModel, index: number) => void;
  onNext: () => void;
  onPrev: () => void;
};

export const CarouselWithControlsComponent: FC<ShowcaseCarouselProps> = ({
  carouselId,
  carouselList,
  focusedIndex,
  type,
  onClick,
  onNext,
  onPrev,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [componentHeight, setComponentHeight] = useState(480);
  const [itemsHeight, setItemsHeight] = useState<number[]>([]);

  useEffect(() => {
    updateHeight();

    async function updateAfterDelay() {
      setTimeout(() => {
        updateHeight();
      }, 2500);
    }

    updateAfterDelay();
  }, [ref]);

  const updateHeight = () => {
    if (ref.current) {
      let longestHeight = 0;
      let indexedHeight: number[] = [];

      ref.current.childNodes.forEach((node, index) => {
        let parsed = node as HTMLDivElement;
        if (
          index !== 0 &&
          index !== (ref.current?.childNodes.length ?? 0) - 1
        ) {
          indexedHeight.push(parsed.scrollHeight + 16);
        }
        if (parsed.scrollHeight > longestHeight) {
          longestHeight = parsed.scrollHeight + 16;
        }
      });

      setComponentHeight(longestHeight);
      setItemsHeight(indexedHeight);
    }
  };

  const getTranslatePosition: (index: number) => string = (index) => {
    let currentPosition = index - focusedIndex;
    currentPosition++;

    if (currentPosition < 0) {
      currentPosition = index + carouselList.length - focusedIndex + 1;
      if (currentPosition < 0) {
        currentPosition *= -1;
      }
      return (currentPosition * 110).toString() + "%";
    }

    if (currentPosition >= carouselList.length) {
      currentPosition -= carouselList.length;
      return (currentPosition * 100).toString() + "%";
    }

    return (currentPosition * 110).toString() + "%";
  };

  const getScaleValue: (index: number) => string = (index) => {
    if (index === focusedIndex) return "1";

    return "0.8";
  };

  return (
    <div className="grid place-items-center mx-7 md:mx-16" id={carouselId}>
      <div
        ref={ref}
        className="w-full relative overflow-hidden"
        style={{
          height: componentHeight + 16 + "px",
        }}
      >
        <button
          className="color-yellow rounded-full w-fit h-fit p-3 absolute left-0 top-[43%] z-30"
          onClick={onPrev}
        >
          <FaArrowLeft size={18} />
        </button>

        {type === CarouselType.TESTIMONI ? (
          <TestimoniCarouselComponent
            carouselList={carouselList as TestimoniCarouselModel[]}
            focusedIndex={focusedIndex}
            onClick={onClick}
            getTranslatePosition={getTranslatePosition}
            getScaleValue={getScaleValue}
            heights={itemsHeight}
          />
        ) : (
          <></>
        )}

        <button
          className="color-yellow rounded-full w-fit h-fit p-3 absolute right-0 top-[43%] z-30"
          onClick={onNext}
        >
          <FaArrowRight size={18} />
        </button>
      </div>
    </div>
  );
};
