import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

/*
 Type = open | atc | checkout
*/
export const TrackAffiliateUsecase: (
  affiliate_name: string,
  type: string,
  url: string
) => void = async (affiliate_name, type, url) => {
  if (affiliate_name === "" || type === "") return;

  try {
    let formData = new FormData();
    formData.append("affiliate_name", affiliate_name);
    formData.append("type", type);
    formData.append("url", url);

    await client.post(ApiConst.API_AFFILIATES + "/track", formData);
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
