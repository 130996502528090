import { ToasterModel } from "@Libraries/components/toast/Toast.model";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

export type ToasterContent = {
  toastList: ToasterModel[];
  setToastList: (list: ToasterModel[]) => void;
};

export const ToasterContext = createContext<ToasterContent>({
  toastList: [],
  setToastList: () => {},
});

export const ToasterProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toastList, setToastList] = useState<ToasterModel[]>([]);
  const value: ToasterContent = { toastList, setToastList };

  return (
    <ToasterContext.Provider value={value}>{children}</ToasterContext.Provider>
  );
};

export function UseToasterContext() {
  return useContext(ToasterContext);
}
