import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const GetWatchlistListUsecase: () => Promise<any> = async () => {
  try {
    let response = await client.get(ApiConst.API_WATCHLIST);
    let parsed = JSON.parse(response.data.data);
    
    return parsed;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
