import { FC } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

export type MembershipModel = {
  productId: string;
  productName: string;
  productDuration: string;
  productBenefit: string;
  productPrice: string;
  slashPrice?: string;
  isFeatured: boolean;
  description?: string;
};

export type MembershipPriceProps = {
  data: MembershipModel[];
};

export const MidasStockMembershipPriceComponent: FC<MembershipPriceProps> = ({
  data,
}) => {
  return (
    <div className="mt-16 mx-7 md:mx-16 text-center">
      <div className="text-white font-bold text-5xl">
        Midas <span className="color-brand-yellow">Stocks Membership</span>
      </div>
      <div className="text-white text-xl font-normal mt-4 pb-4">
        Pilih paket membership yang terbaik untuk anda
      </div>
      <div
        className={`mt-12 rounded-3xl md:bg-midas-price grid grid-cols-1 gap-4 md:mt-20 md:flex md:items-end md:p-10 md:static`}
      >
        {data.map((item, index) => (
          <div
            key={`product-${item.productId}`}
            className={`w-full md:w-1/3 ${item.isFeatured ? "relative" : ""}`}
          >
            <div
              className={`rounded-3xl text-left w-full ${
                item.isFeatured
                  ? "md:overflow-auto color-yellow py-4 px-6 md:absolute md:-top-[455px] md:bottom-0"
                  : "bg-midas-price md:relative p-10 md:bg-none md:rounded-none md:p-0 md:pb-4"
              }`}
            >
              {(item.slashPrice || item.isFeatured) && (
                <div className="flex justify-between">
                  {item.slashPrice ? (
                    <div className="text-xl font-normal line-through color-020202 opacity-50 my-auto">
                      {item.slashPrice}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {item.isFeatured ? (
                    <div className="uppercase text-[10px] text-white rounded-2xl bg-midas-neutral-900 px-3 py-2 my-auto">
                      Best Choice
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              <div className="font-bold text-3xl">{item.productPrice}</div>
              <div className="font-normal text-xl">
                per{" "}
                {item.productDuration.includes("day")
                  ? item.productDuration.replace("day", "hari")
                  : item.productDuration.includes("month")
                  ? item.productDuration.replace("month", "bulan")
                  : item.productDuration.replace("year", "tahun")}
              </div>
              {item.isFeatured && (
                <div className="text-sm font-normal text-left mt-2 color-020202">
                  Untuk anda yang serius ingin sukses berinvestasi saham &
                  mendapatkan benefit yang menyeluruh dari program ini
                </div>
              )}
              {item.productBenefit.split(",").map((benefit, index) => (
                <div
                  className={`flex mb-2 ${index === 0 ? "mt-6" : "mt-0"}`}
                  key={`product-${index}-${benefit
                    .replaceAll(" ", "-")
                    .toLowerCase()}`}
                >
                  <div
                    className="my-auto"
                    style={{ color: "rgba(2, 2, 2, 0.1)" }}
                  >
                    <FaCheckCircle size={20} />
                  </div>
                  <div className="font-normal text-base ml-3 my-auto">
                    {benefit.trim()}
                  </div>
                </div>
              ))}
              <Link to={`/add-to-cart?product=${item.productId}`}>
                <div
                  className={`mt-6 rounded-3xl text-lg text-center py-3  font-bold ${
                    item.isFeatured
                      ? "bg-midas-neutral-900 color-brand-yellow px-0"
                      : "color-yellow color-020202 px-6"
                  }`}
                >
                  Gabung sekarang
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
