import { FC } from "react";

export type ImageOptions = {
  width: number | null;
  widthPercentage: number;
  height: number | null;
  objectFit: string;
};

export type ImageProps = {
  componentKey: string;
  options: ImageOptions;
  imageUrl: string;
  altText: string;
};

export const MidasImage: FC<ImageProps> = ({
  componentKey,
  options,
  imageUrl,
  altText,
}) => {
  let styles: { [key: string]: any } = {
    objectFit: options.objectFit,
  };

  if (options.width != null) {
    styles["width"] = options.width + "px";
  } else if (options.widthPercentage != null) {
    styles["width"] = options.widthPercentage + "%";
  }

  if (options.height != null) {
    styles["height"] = options.height + "px";
  }

  return (
    <img
      key={componentKey}
      src={imageUrl}
      className="block mx-auto"
      style={styles}
      alt={altText}
    />
  );
};
