import { MidasLogo } from "@Assets/midas-logo";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export type HeaderLogoOnlyProps = {
  leftComponent?: ReactNode;
};

export const HeaderLogoOnly: FC<HeaderLogoOnlyProps> = ({ leftComponent }) => {
  return (
    <nav
      className={`bg-midas-neutral-900 flex px-4 ${
        leftComponent ? "justify-between" : "justify-center"
      }`}
    >
      {leftComponent && <div>{leftComponent}</div>}
      <div>
        <Link to={"/"}>
          <MidasLogo className={""} />
        </Link>
      </div>
      {leftComponent && (
        <div className="visibility-hidden">{leftComponent}</div>
      )}
    </nav>
  );
};
