import { GetGroupListUsecase } from "@Features/dashboard/domain/GetGroupList.usecase";
import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { FC, ReactNode, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { FaTelegram } from "react-icons/fa";
import {
  DISCUSSION_GROUP,
  STRATEGY_ROOM,
} from "@Libraries/utils/Const";

export type ToolsItemModel = {
  icon: ReactNode;
  text: ReactNode;
  url: string;
  showNewLabel: boolean;
};

export const GroupItemComponent: FC<ToolsItemModel> = ({
  icon,
  text,
  url,
  showNewLabel,
}) => {
  return (
    <a href={url} target={"_blank"} rel={"noreferrer"}>
      <div className="relative rounded-lg bg-midas-neutral-500 p-4 h-full text-center hover:text-black">
        <div className="flex justify-center">{icon}</div>
        <div className="text-base font-semibold text-center mt-2">{text}</div>
        {showNewLabel && (
          <div
            className="absolute top-0 right-0 bg-red-500 rounded text-white px-2 py-1"
            style={{ fontSize: 10 }}
          >
            Terbaru!
          </div>
        )}
      </div>
    </a>
  );
};

export const GroupDiscussionSection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSettings, setPageSettings] = useState<PageSettingListModel>();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setIsLoading(true);
      let response = await GetGroupListUsecase();
      setPageSettings(response);
    } catch (e) {
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      {pageSettings && (
        <div className="mt-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          <Helmet encodeSpecialCharacters={true}>
            <title>Grup Diskusi - MIDAS Cuan</title>
            <meta name="description" content="MIDAS Cuan - Grup diskusi" />
          </Helmet>
          <GroupItemComponent
            icon={<FaTelegram size={"50%"} />}
            text={"Grup Diskusi Member"}
            url={pageSettings[DISCUSSION_GROUP]}
            showNewLabel={false}
          />
          <GroupItemComponent
            icon={<FaTelegram size={"50%"} />}
            text={"Strategy Room (Grup Pengumuman)"}
            url={pageSettings[STRATEGY_ROOM]}
            showNewLabel={false}
          />
        </div>
      )}
    </div>
  );
};
